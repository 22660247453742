import { FileTextOutlined } from "@ant-design/icons";
import { Button, Popconfirm, Tooltip } from "antd";
import { FC, useState } from "react";

type GenerateInvoiceButtonProps = {
  name: string;
  email: string;
  pendingWells: number;
  pendingAssignedTokens: number;
  pendingReplacedTokens: number;
  pendingOther: number;
  user: any;
  onGenerateInvoice: (user: any, onCompleted: () => void) => void;
};

const GenerateInvoiceButton: FC<GenerateInvoiceButtonProps> = (props) => {
  const { email, name, pendingAssignedTokens, pendingReplacedTokens, pendingWells, pendingOther, onGenerateInvoice, user } = props;

  const [loading, setLoading] = useState<boolean>(false);

  return (
    <Popconfirm
      placement="topRight"
      title="Generate Invoice"
      description={
        <pre style={{ fontFamily: "inherit" }}>
          Are you sure you want to generate a{pendingWells === 0 && pendingAssignedTokens === 0 && pendingReplacedTokens === 0 && pendingOther === 0 ? " BLANK" : ""} invoice for the pending billable
          items?
          <br />
          <br />
          Name: {name}
          <br />
          Email: {email?.toLowerCase()}
          <br />
          <br />
          Wells: {pendingWells}
          <br />
          Assigned Tokens: {pendingAssignedTokens}
          <br />
          Replaced Tokens: {pendingReplacedTokens}
          <br />
          Other Billable Items: {pendingOther}
        </pre>
      }
      onConfirm={() => {
        setLoading(true);
        onGenerateInvoice(user, () => setLoading(false));
      }}
      okText={pendingWells === 0 && pendingAssignedTokens === 0 && pendingReplacedTokens === 0 && pendingOther === 0 ? "Generate BLANK Invoice" : "Generate Invoice"}
      cancelText="No"
    >
      <Tooltip title="Generate invoice" placement="left">
        <Button loading={loading} style={{ paddingLeft: 0, paddingRight: 0 }} type="link" icon={<FileTextOutlined />} />
      </Tooltip>
    </Popconfirm>
  );
};

export default GenerateInvoiceButton;
