import useDashboardOrders from "@/services/useDashboardOrders";
import useFeatureFlag, { FeatureFlagType } from "@/services/useFeatureFlag";
import { MenuUnfoldOutlined } from "@ant-design/icons";
import { DndContext, DragEndEvent } from "@dnd-kit/core";
import type { SyntheticListenerMap } from "@dnd-kit/core/dist/hooks/utilities";
import { restrictToVerticalAxis } from "@dnd-kit/modifiers";
import { arrayMove, SortableContext, useSortable, verticalListSortingStrategy } from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";
import { Button, Divider, Input, Table, TableColumnsType, TableProps } from "antd";
import { createContext, CSSProperties, Dispatch, FC, HTMLAttributes, Key, SetStateAction, useContext, useEffect, useMemo, useState } from "react";

interface Props {
  order: string[];
  setOrder: Dispatch<SetStateAction<string[]>>;
  defaultKey: string;
  setDefaultKey: Dispatch<SetStateAction<string>>;
}

interface RowContextProps {
  setActivatorNodeRef?: (element: HTMLElement | null) => void;
  listeners?: SyntheticListenerMap;
}

interface RowProps extends HTMLAttributes<HTMLTableRowElement> {
  "data-row-key": string;
}

const RowContext = createContext<RowContextProps>({});

const DragHandle: FC = () => {
  const { setActivatorNodeRef, listeners } = useContext(RowContext);
  return <Button type="text" size="small" icon={<MenuUnfoldOutlined />} style={{ cursor: "move" }} ref={setActivatorNodeRef} {...listeners} />;
};

const columns: TableColumnsType<any> = [
  { key: "sort", align: "center", width: 80, render: () => <DragHandle /> },
  { title: "Tab", dataIndex: "tab" },
  {
    title: "Default",
    key: "default",
    width: 50,
    render: (_, record, index) => <Input type="radio" />,
  },
];

const Row: FC<RowProps> = (props) => {
  const { attributes, listeners, setNodeRef, setActivatorNodeRef, transform, transition, isDragging } = useSortable({ id: props["data-row-key"] });

  const style: CSSProperties = {
    ...props.style,
    transform: CSS.Translate.toString(transform),
    transition,
    ...(isDragging ? { position: "relative", zIndex: 9999 } : {}),
  };

  const contextValue = useMemo<RowContextProps>(() => ({ setActivatorNodeRef, listeners }), [setActivatorNodeRef, listeners]);

  return (
    <RowContext.Provider value={contextValue}>
      <tr {...props} ref={setNodeRef} style={style} {...attributes} />
    </RowContext.Provider>
  );
};

const DashboardOrdersSection: FC<Props> = (props) => {
  const { order, setOrder, defaultKey, setDefaultKey } = props;

  const { dashboardOrders } = useDashboardOrders();

  const { hasFeature: hasAnalyticsDashboard } = useFeatureFlag(FeatureFlagType.ANALYTICS_DASHBOARD);

  const initialData: any[] = [
    { key: "2", tab: "Operations" },
    { key: "3", tab: "Gauges" },
    { key: "4", tab: "Map" },
    { key: "5", tab: "Actions" },
  ];

  if (hasAnalyticsDashboard) {
    initialData.unshift({
      key: "1",
      tab: "Analytics",
    });
  }

  const [dataSource, setDataSource] = useState<any[]>(() => dashboardOrders.map((orderKey) => initialData.find((item) => item.key === orderKey.value)).filter((item) => item) || initialData);

  const [selectedRowKey, setSelectedRowKey] = useState<string>(defaultKey);

  useEffect(() => {
    setOrder(dataSource.map((source: any) => source.key));
    // eslint-disable-next-line
  }, [dataSource]);

  const onDragEnd = ({ active, over }: DragEndEvent) => {
    if (active.id !== over?.id) {
      setDataSource((prevState) => {
        const activeIndex = prevState.findIndex((record) => record.key === active?.id);
        const overIndex = prevState.findIndex((record) => record.key === over?.id);
        return arrayMove(prevState, activeIndex, overIndex);
      });
    }
  };

  const onRadioChange = (key: string) => {
    setSelectedRowKey(key);
    setDefaultKey(key);
  };

  const columns: TableColumnsType<any> = [
    {
      key: "sort",
      align: "center",
      width: 80,
      render: () => <DragHandle />,
    },
    {
      title: "Tab",
      dataIndex: "tab",
    },
    {
      title: "Default",
      key: "default",
      width: 50,
      render: (_, record) => <Input type="radio" checked={record.key === selectedRowKey} onChange={() => onRadioChange(record.key)} />,
    },
  ];

  return (
    <>
      <DndContext modifiers={[restrictToVerticalAxis]} onDragEnd={onDragEnd}>
        <SortableContext items={dataSource.map((i) => i.key)} strategy={verticalListSortingStrategy}>
          <Table<any> rowKey="key" components={{ body: { row: Row } }} columns={columns} dataSource={dataSource} pagination={false} />
        </SortableContext>
      </DndContext>
    </>
  );
};

export default DashboardOrdersSection;
