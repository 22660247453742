import { ProfileUpdateModal } from "@/components";
import useActionsCounter from "@/queries/useActionsCounter";
import useOutstandingCompanyCounts from "@/queries/useOutstandingCompanyCounts";
import useCustomNavigate from "@/services/useCustomNavigate";
import useDashboardOrders from "@/services/useDashboardOrders";
import useFeatureFlag, { FeatureFlagType } from "@/services/useFeatureFlag";
import { DashboardOutlined, EnvironmentOutlined, FileSearchOutlined, LineChartOutlined, SettingOutlined, ThunderboltOutlined } from "@ant-design/icons";
import { Badge, Button, Card, notification, Space, Tabs, Tooltip } from "antd";
import ButtonGroup from "antd/es/button/button-group";
import { FC, useEffect, useState } from "react";
import ActionsDashboard from "./components/ActionsDashboard";
import AnalyticsDashboard from "./components/AnalyticsDashboard";
import GaugesDashboard from "./components/GaugesDashboard";
import MapDashboard from "./components/MapDashboard";
import OperationsDashboard from "./components/OperationsDashboard";

const Dashboard: FC = () => {
  const { searchParams, updateQueryParams } = useCustomNavigate();

  const [api, contextHolder] = notification.useNotification();

  const { actionsCount } = useActionsCounter();
  const { count: outstandingCompanyCounts } = useOutstandingCompanyCounts();

  const { hasFeature: hasAnalyticsDashboard } = useFeatureFlag(FeatureFlagType.ANALYTICS_DASHBOARD);
  const { hasFeature: hasDashboardOrders } = useFeatureFlag(FeatureFlagType.DASHBOARD_ORDERS);

  const { dashboardOrders } = useDashboardOrders();

  const [profileModalState, setProfileModalState] = useState<any>({
    data: undefined,
    open: false,
    activeTab: "dashboardOrders",
  });

  const [tab, setTab] = useState<string | undefined>(() => dashboardOrders?.find((order: any) => order.default === true)?.value);

  useEffect(() => {
    if (!tab && dashboardOrders) {
      const defaultTab = dashboardOrders.find((order: any) => order.default === true)?.value;
      if (defaultTab) {
        setTab(defaultTab);
      }
    }
  }, [dashboardOrders, tab]);

  useEffect(() => {
    const searchTab = searchParams?.get("tab");
    if (searchTab) {
      setTab(searchTab);
    }
  }, [searchParams]);

  const tabs: {
    label: string;
    key: string;
    children: JSX.Element;
    icon: JSX.Element;
    hidden?: boolean;
    tooltip?: string;
    hiddenTooltip?: string;
    badgeCount?: number | (() => number);
  }[] = [
    {
      label: "Operations",
      key: "2",
      children: <OperationsDashboard />,
      icon: <FileSearchOutlined />,
      tooltip: "An operational overview of the company data",
    },
    {
      label: "Gauges",
      key: "3",
      children: <GaugesDashboard />,
      icon: <DashboardOutlined />,
      tooltip: "A visual overview of the company data",
    },
    {
      label: "Map",
      key: "4",
      children: <MapDashboard />,
      icon: <EnvironmentOutlined />,
      tooltip: "A visual map of all wells",
    },
    {
      label: "Actions",
      key: "5",
      children: <ActionsDashboard />,
      icon: <ThunderboltOutlined />,
      badgeCount: actionsCount + (outstandingCompanyCounts === undefined ? 0 : outstandingCompanyCounts),
    },
  ];

  if (hasAnalyticsDashboard) {
    tabs.unshift({
      label: "Analytics",
      key: "1",
      children: <AnalyticsDashboard />,
      icon: <LineChartOutlined />,
      tooltip: "An analytics overview of the company data",
    });
  }

  const orderedTabs = dashboardOrders ? dashboardOrders.map((order: any) => tabs.find((tab) => tab.key === order.value)).filter((tab): tab is (typeof tabs)[0] => !!tab) : tabs;

  const openProfileUpdateCompleteNotification = (closeModal?: boolean) => {
    setProfileModalState({ ...profileModalState, open: closeModal });

    const key = `open${Date.now()}`;
    const btn = (
      <Space>
        <Button type="default" onClick={() => api.destroy(key)}>
          Close
        </Button>
      </Space>
    );
    api.success({
      message: "Update profile success",
      description: "Your profile was successfuly updated.",
      btn,
      key,
      duration: 0,
      placement: "top",
    });
  };

  const handleProfileCancel = () => {
    setProfileModalState({ ...profileModalState, open: false });
  };

  const handleManageProfile = () => {
    setProfileModalState({ ...profileModalState, open: true });
  };

  return (
    <>
      {contextHolder}
      <Card
        bordered={false}
        style={{ background: "transparent" }}
        className="removeBoxShadow removeMargin"
        styles={{
          body: {
            padding: 0,
            marginRight: 8,
          },
        }}
      >
        {tab && (
          <Tabs
            activeKey={tab}
            centered
            tabPosition={"top"}
            items={tabs}
            tabBarStyle={{ marginBottom: 5 }}
            renderTabBar={(props) => (
              <Card
                styles={{
                  body: {
                    padding: 5,
                    marginRight: 8,
                  },
                }}
              >
                <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                  <ButtonGroup>
                    {orderedTabs.map((tab, index) => (
                      <Tooltip key={tab.key} title={tab.hidden ? tab.hiddenTooltip : tab.tooltip}>
                        <Badge count={typeof tab.badgeCount === "number" ? tab.badgeCount : tab.badgeCount?.()} style={{ zIndex: 99 }} offset={[0, 5]} color="red">
                          <Button key={tab.key} type={props.activeKey === tab.key ? "primary" : "default"} icon={tab.icon} onClick={() => updateQueryParams({ tab: tab.key })} disabled={tab.hidden}>
                            {tab.label}
                          </Button>
                        </Badge>
                      </Tooltip>
                    ))}
                  </ButtonGroup>
                  {hasDashboardOrders && <SettingOutlined onClick={handleManageProfile} style={{ fontSize: 16 }} />}
                </div>
              </Card>
            )}
          />
        )}
      </Card>
      {profileModalState.open && (
        <ProfileUpdateModal open={profileModalState.open} onSuccess={openProfileUpdateCompleteNotification} onCancel={handleProfileCancel} activeTab={profileModalState.activeTab} />
      )}
    </>
  );
};

export default Dashboard;
