import { syncRestrictionCalculations } from "@/apis/restriction.api";
import LookupFilter from "@/components/LookupFilter/LookupFilter";
import LookupLabel from "@/components/LookupLabel/LookupLabel";
import CompanyLabel from "@/components/Lookups/CompanyLabel";
import PrintPage from "@/components/PrintPage/PrintPage";
import RemainingDays from "@/components/RemainingDays/RemainingDays";
import RestrictionPopover from "@/components/RestrictionPopover/RestrictionPopover";
import StatusTag from "@/components/StatusTag/StatusTag";
import UnitsConverter from "@/components/UnitsConverter/UnitsConverter";
import WaterRightTagList from "@/components/WaterRightTagList/WaterRightTagList";
import { constants, routes } from "@/configs";
import { UserRole } from "@/dtos/user.dto";
import { WaterRightView } from "@/pages";
import useFieldLookups from "@/queries/useFieldLookups";
import useListWaterRightQuery from "@/queries/useListWaterRightQuery";
import useRestrictionLookups from "@/queries/useRestrictionLookups";
import useWaterRightCalculations from "@/queries/useWaterRightCalculations";
import useWellLookups from "@/queries/useWellLookups";
import useCustomNavigate from "@/services/useCustomNavigate";
import { useAppDispatch } from "@/stores";
import { addBreadcrumb } from "@/stores/breadcrumbs.store";
import { PlusOutlined, ReloadOutlined, WarningOutlined } from "@ant-design/icons";
import { useIntersectionObserver } from "@uidotdev/usehooks";
import { Button, Card, Empty, Input, message, Popover, Space, Switch, Table } from "antd";
import dayjs from "dayjs";
import { FC, memo, useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useSelector } from "react-redux";
import AddSelectCompanyModal, { AddSelectCompanyModalRef, EnitityName } from "../AddSelectCompanyModal/AddSelectCompanyModal";
import DWRSearchWaterRightModal from "../DWRComponents/DWRSearchWaterRightModal/DWRSearchWaterRightModal";
import JoinedItems from "../JoinedItems/JoinedItems";
import SelectWaterRightTypeModal from "../SelectWaterRightTypeModal/SelectWaterRightTypeModal";
import useFeatureFlag, { FeatureFlagType } from "@/services/useFeatureFlag";

const maxFieldWellLength = 30;

interface Props {
  refreshRestrictionListCalculations?: () => void;
  style?: React.CSSProperties;
  waterRightIds?: any[] | null;
  showAddWaterRight: boolean;
  showHeader: boolean;
}

const WaterRightTable: FC<Props> = (props) => {
  const { hasFeature: hasDWR } = useFeatureFlag(FeatureFlagType.DWR_INTEGRATION);

  const { refreshRestrictionListCalculations, style, waterRightIds, showAddWaterRight, showHeader } = props;

  const dispatch = useAppDispatch();

  const { selectedCompanyId, selectedCompany } = useSelector((state: any) => state.company);
  const { lookups } = useSelector((state: any) => state.lookups);

  const { navigate } = useCustomNavigate();

  const { wells } = useWellLookups();
  const { fields } = useFieldLookups();
  const { restrictions } = useRestrictionLookups();

  const [columns, setColumns] = useState<any[]>([]);
  const [searchStringValue, setSearchStringValue] = useState<string | undefined>("");
  const [searchString, setSearchString] = useState<string | undefined>("");
  const [isActive, setIsActive] = useState<boolean | null>(true);

  const [loadingRestrictionCalculations, setLoadingRestrictionCalculations] = useState<boolean>(true);
  const [restrictionCalculations, setRestrictionCalculations] = useState<any[]>([]);
  const [permitTypes, setPermitTypes] = useState<any[]>([]);

  const [waterRightsDownRef, waterRightsDownEntry] = useIntersectionObserver();

  const [waterRightTypeModalState, setWaterRightTypeModalState] = useState<any>({ open: false });

  const [dwrWaterRightModalState, setDwrWaterRightModalState] = useState<any>({
    open: false,
  });

  const {
    waterRightList: tempWaterRights,
    isLoading,
    invalidateWaterRights,
    isFetchingNextPage,
    hasNextPage,
    fetchNextPage,
    totalItemCount,
  } = useListWaterRightQuery({
    isActive,
    permitTypes: permitTypes?.length > 0 ? permitTypes : null,
    searchString,
    waterRightIds,
  });

  const { waterRightCalculations, isLoading: loadingCalculations } = useWaterRightCalculations(waterRightIds ?? tempWaterRights?.map((x: any) => x.id) ?? [], dayjs().year());

  const waterRights: any[] = useMemo(() => {
    const data = tempWaterRights?.map((waterRight: any) => {
      const wellsForWaterRight = wells?.filter((well: any) => well.waterRightId === waterRight.id);
      const fieldsForWaterRight = wellsForWaterRight.map((well: any) => well.fieldId);
      const tempWells = wellsForWaterRight.map((well: any) => well.name);
      const tempFields = fields.filter((field: any) => fieldsForWaterRight.includes(field.id)).map((field: any) => field.name);

      return {
        ...waterRight,
        wells: tempWells?.length > 0 ? tempWells : ["-"],
        fields: tempFields.length > 0 ? tempFields : ["-"],
      };
    });

    return data;
  }, [tempWaterRights, wells, waterRightCalculations, fields, selectedCompanyId]);

  const refreshWaterRightCalculations = useCallback(() => {
    handleRefresh();
  }, []);

  useEffect(() => {
    if (waterRightsDownEntry?.isIntersecting && hasNextPage) {
      fetchNextPage();
    }
    // eslint-disable-next-line
  }, [waterRightsDownEntry?.time, waterRightsDownEntry?.isIntersecting, hasNextPage]);

  useEffect(() => {
    refreshRestrictionCalculations();
  }, [isActive, permitTypes, selectedCompanyId, searchString]);

  useEffect(() => {
    calculateColumns();
    // eslint-disable-next-line
  }, [waterRights, restrictions, restrictionCalculations, selectedCompanyId]);

  const handleCompanySelectSuccess = (company: any) => {
    handleAddWaterRight();
  };

  const handleAddWaterRight = () => {
    if (selectedCompany?._companyId === "all" && companySelectModalRef.current?.isOpen === false) {
      companySelectModalRef.current?.open();
      return;
    }

    if (hasDWR) {
      setWaterRightTypeModalState({ open: true });
    } else {
      handleNavigateToAdd();
    }
  };

  const handleNavigateToAdd = () => {
    dispatch(
      addBreadcrumb({
        type: `Water Right / Add`,
        url: `waterright/add`,
      })
    );
    navigate(routes.waterRightAdd);
  };

  const handleDwrModalStateCancel = () => {
    setDwrWaterRightModalState({
      ...dwrWaterRightModalState,
      open: false,
    });
  };

  const handleDwrModalStateSuccess = (data: any) => {
    handleDwrModalStateCancel();

    message.success(`Successfully selected water right ${data} for import`);

    // TODO: Handle auto populate
  };

  const handleSelectWaterRightTypeCancel = () => {
    setWaterRightTypeModalState({ ...waterRightTypeModalState, open: false });
  };

  const handleSelectWaterRightTypeSuccess = (type: any) => {
    handleSelectWaterRightTypeCancel();

    if (type === "dwr") {
      setDwrWaterRightModalState({ open: true });
    } else {
      handleNavigateToAdd();
    }
  };

  const handleViewWaterRight = (record: any) => {
    // Add view breadcrumb
    dispatch(
      addBreadcrumb({
        type: `${record.fileNumber}`,
        url: `waterright/${record.id}/view`,
      })
    );

    navigate(routes.waterRightView, { id: record.id });
  };

  // const handleViewRestriction = (record: any) => {
  //   dispatch(
  //     addBreadcrumb({
  //       type: `${record.name}`,
  //       url: `restriction/${record.id}/view`,
  //     })
  //   );
  //   navigate(routes.restrictionView, { id: record.id });
  // };

  const refreshRestrictionCalculations = async () => {
    setLoadingRestrictionCalculations(true);
    const response = await syncRestrictionCalculations({});
    if (response.ok) {
      const data = await response.json();
      if (data.isSuccess) {
        setRestrictionCalculations(data.value);
      }
    }
    setLoadingRestrictionCalculations(false);
  };

  const determineValue = (
    id: string,
    type: string,
    postfix: any = undefined,
    irrigationAcresNotApplicable: boolean | undefined = undefined,
    quantityNotApplicable: boolean | undefined = undefined,
    rateNotApplicable: boolean | undefined = undefined
  ) => {
    const existingCalculation = waterRightCalculations.find((calculation: any) => calculation.waterRightId === id && dayjs().year() === calculation.year);

    if (existingCalculation) {
      if (type === "remainingUsage" && existingCalculation.quantityNotApplicable === true) return "N/A";
      else if (type === "remainingDays" && (existingCalculation.quantityNotApplicable === true || existingCalculation.rateNotApplicable === true)) return "N/A";
      return existingCalculation[type];
    } else {
      if (type === "remainingUsage" && quantityNotApplicable === true) return "N/A";
      else if (type === "remainingDays" && (quantityNotApplicable === true || rateNotApplicable === true)) return "N/A";
      else return undefined;
    }
  };

  const renderRestrictionSummaries = (record: any) => {
    const restrictionIds = restrictions.filter((restriction: any) => restriction.waterRightIds?.includes(record.id) && restriction?.status === true).map((restriction: any) => restriction.id);
    const existingCalculations = restrictionCalculations.filter((calculation: any) => restrictionIds.includes(calculation.id));

    if (existingCalculations.length === 0) return <>-</>;

    return existingCalculations.map((calculation: any, index: number, list: any[]) => (
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "flex-start",
          alignItems: "center",
          marginBottom: index < list.length - 1 ? 10 : undefined,
        }}
      >
        <RestrictionPopover
          status={calculation?.status}
          companyId={record?.companyId}
          selectedCompany={selectedCompany}
          startDate={dayjs(calculation?.startDate)}
          endDate={dayjs(calculation?.endDate)}
          remainingYears={calculation?.remainingYears}
          calculation={calculation}
          type={calculation?.type}
        />
        <div style={{ fontSize: "0.8em" }}>
          {selectedCompanyId && selectedCompany?.settings?.metric !== "acrefeet" && (
            <div>
              <UnitsConverter fromUnits="gallons" showUnitsLabel value={calculation.remainingYearlyAverage} />
            </div>
          )}
          <div>
            <UnitsConverter fromUnits="gallons" toUnits="acrefeet" showUnitsLabel value={calculation.remainingYearlyAverage} />
          </div>
          {calculation.inchesPerAcre !== null && (
            <div>
              <UnitsConverter fromUnits="inchesperacre" toUnits="inchesperacre" value={calculation.inchesPerAcre} postFix="Inches / Acre" />
            </div>
          )}
        </div>
      </div>
    ));
  };

  const percentageAvailable = (remainingUsage: any, availableQuantity: number) => {
    if (remainingUsage === undefined || availableQuantity === undefined || availableQuantity === 0) return undefined;

    if (remainingUsage < 0 || remainingUsage === "N/A") return "0.00 %";
    return ((remainingUsage / availableQuantity) * 100).toFixed(2) + " %";
  };

  const calculateColumns = () => {
    let tempColumns = [
      {
        title: (
          <>
            Water Right /<br /> File Number
          </>
        ),
        key: "fileNumber",
        dataIndex: "fileNumber",
        render: (val: any, record: any, index: any) => (
          <>
            <Button style={{ paddingLeft: 0 }} type="link" onClick={() => handleViewWaterRight(record)}>
              {val}{" "}
              {record?.disableWarnings ? (
                <Popover
                  content={
                    <>
                      <b>Warnings Disabled</b> <br />
                      Acknowledged By: {record?.markedAsDisabledUserUserName} <br />
                      Date: {dayjs(record?.markedAsDisabledDate ?? undefined).format(constants.dateTimeFormat)} <br />
                    </>
                  }
                >
                  <WarningOutlined style={{ color: "red" }} />
                </Popover>
              ) : undefined}
            </Button>
            <WaterRightTagList internalTags={record.internalTags} externalTags={record.externalTags} style={{ maxWidth: 200 }} />
            {index === waterRights?.length - 1 ? <span ref={waterRightsDownRef} /> : null}
          </>
        ),
      },
      // {
      //   title: (
      //     <>
      //       PDIV <br /> CIN
      //     </>
      //   ),
      //   key: "pdivcin",
      //   width: 100,
      //   render: (val: any, record: any, index: any) => (
      //     <>
      //       <div style={{ padding: 0, margin: 0 }}>{record?.pdiv ?? "-"}</div>
      //       <div style={{ padding: 0, margin: 0 }}>{record?.cin ?? "-"}</div>
      //     </>
      //   ),
      // },
      {
        title: "Fields",
        key: "fields",
        dataIndex: "fields",
        render: (val: any, record: any) => <JoinedItems items={record?.fields} maxLength={maxFieldWellLength} title={"Fields for Water Right"} />,
      },
      {
        title: "Wells",
        key: "wells",
        dataIndex: "wells",
        render: (val: any, record: any) => <JoinedItems items={record?.wells} maxLength={maxFieldWellLength} title={"Wells for Water Right"} />,
      },
      {
        title: "Status",
        key: "status",
        dataIndex: "status",
        render: (val: any, record: any) => <StatusTag status={val} />,
      },
      {
        title: "Permit Type",
        key: "permitType",
        dataIndex: "permitType",
        render: (val: any, record: any) => <LookupLabel dontShowCode lookupType={"permittype"} value={val} />,
      },
      {
        title: "Authorized / Usage Rate",
        key: "usageRate",
        dataIndex: "usageRate",
        render: (val: any, record: any) =>
          record?.authorizedRateNotApplicable === true ? "N/A" : record?.usageRate ? record?.usageRate + " GPM" : record?.authorizedRate ? record?.authorizedRate + " GPM" : "-",
      },
      {
        title: "Available Quantity",
        key: "usageRate",
        dataIndex: "usageRate",
        render: (val: any, record: any) =>
          record?.authorizedQuantityNotApplicable === true ? (
            "N/A"
          ) : (
            <UnitsConverter fromUnits="gallons" toUnits={selectedCompanyId ? selectedCompany?.settings?.metric : "acrefeet"} value={determineValue(record.id, "availableQuantity")} showUnitsLabel />
          ),
      },
      {
        title: "Current Usage",
        key: "currentUsage",
        dataIndex: "currentUsage",
        render: (val: any, record: any) => (
          <UnitsConverter fromUnits="gallons" toUnits={selectedCompanyId ? selectedCompany?.settings?.metric : "acrefeet"} value={determineValue(record.id, "currentUsage")} showUnitsLabel />
        ),
      },
      {
        title: "Remaining Usage",
        key: "remainingUsage",
        dataIndex: "remainingUsage",
        render: (val: any, record: any) => (
          <UnitsConverter
            fromUnits="gallons"
            toUnits={selectedCompanyId ? selectedCompany?.settings?.metric : "acrefeet"}
            value={determineValue(
              record.id,
              "remainingUsage",
              null,
              record?.authorizedIrrigationNotApplicable === true ? true : undefined,
              record?.authorizedQuantityNotApplicable === true ? true : undefined,
              record?.authorizedRateNotApplicable === true ? true : undefined
            )}
            showUnitsLabel
          />
        ),
      },
      {
        title: "Remaining Days / %",
        key: "remainingDays",
        dataIndex: "remainingDays",
        render: (val: any, record: any) => {
          return (
            <>
              <RemainingDays
                remainingDays={determineValue(
                  record.id,
                  "remainingDays",
                  null,
                  record?.authorizedIrrigationNotApplicable === true ? true : undefined,
                  record?.authorizedQuantityNotApplicable === true ? true : undefined,
                  record?.authorizedRateNotApplicable === true ? true : undefined
                )}
              />
              <br />
              {!!record?.authorizedQuantityNotApplicable === false &&
                percentageAvailable(
                  determineValue(
                    record.id,
                    "remainingUsage",
                    null,
                    record?.authorizedIrrigationNotApplicable === true ? true : undefined,
                    record?.authorizedQuantityNotApplicable === true ? true : undefined,
                    record?.authorizedRateNotApplicable === true ? true : undefined
                  ),
                  determineValue(
                    record.id,
                    "availableQuantity",
                    null,
                    record?.authorizedIrrigationNotApplicable === true ? true : undefined,
                    record?.authorizedQuantityNotApplicable === true ? true : undefined,
                    record?.authorizedRateNotApplicable === true ? true : undefined
                  )
                )}
            </>
          );
        },
      },

      {
        title: "Remaining Yearly Average",
        key: "yearlyAverage",
        dataIndex: "yearlyAverage",
        width: 200,
        render: (val: any, record: any) => renderRestrictionSummaries(record),
      },
      {
        title: "Actions",
        key: "action",
        render: (value: any, record: any) => (
          <>
            <Button style={{ paddingLeft: 0 }} type="link" onClick={() => handleViewWaterRight(record)}>
              View
            </Button>
          </>
        ),
      },
    ];

    if (!selectedCompanyId) {
      tempColumns.splice(11, 0, {
        title: "Company",
        key: "companyId",
        dataIndex: "companyId",
        render: (val: any, record: any) => <CompanyLabel companyId={val} />,
      });
    }

    setColumns(tempColumns);
  };

  const handleRefresh = () => {
    setSearchString("");
    setSearchStringValue("");
    invalidateWaterRights();
    refreshRestrictionCalculations();
  };

  const renderExpandData = (record: any) => {
    return <WaterRightView refreshWaterRightCalculations={refreshWaterRightCalculations} overrideId={record.id} showHeader={false} />;
  };

  const handleOnChange = (value: boolean) => {
    if (!value) setIsActive(null);
    else setIsActive(true);
  };

  const handleOnSelectAll = () => {
    const permitTypes = lookups?.find((lookup: any) => lookup.map === "permittype");
    const allPermitTypes = permitTypes?.options?.map((options: any) => options.value);
    setPermitTypes(allPermitTypes);
  };

  const companySelectModalRef = useRef<AddSelectCompanyModalRef>(null);

  const renderContent = () => {
    return (
      <div>
        <Table
          className="customScrollBarStyle"
          pagination={false}
          rowKey={(row: any) => row.id}
          scroll={{ y: waterRightIds !== undefined && waterRightIds && waterRightIds.length > 0 ? "calc(100vh - 650px)" : "calc(100vh - 385px)" }}
          expandable={{
            columnWidth: 55,
            expandedRowRender: (record: any) => renderExpandData(record),
          }}
          loading={isLoading || isFetchingNextPage}
          columns={columns}
          dataSource={waterRights ?? []}
          size="small"
          locale={{
            emptyText: <Empty description="No Water Rights" image={Empty.PRESENTED_IMAGE_SIMPLE} />,
          }}
        />
      </div>
    );
  };

  return (
    <>
      <Card
        styles={{
          body: {
            height: waterRightIds !== undefined && waterRightIds && waterRightIds.length > 0 ? "calc(100vh-595px)" : "calc(100vh - 320px)",
            padding: 0,
          },
        }}
        style={style}
        className="waterRightList"
        id="waterRightList"
        title={
          <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", alignItems: "center" }}>
            <span>Water Rights</span>
            {showHeader && (
              <Space>
                <Input.Search
                  disabled={isLoading}
                  placeholder="Search water rights"
                  onSearch={(val) => {
                    setSearchString(val);
                  }}
                  onChange={(val) => setSearchStringValue(val.currentTarget.value)}
                  value={searchStringValue}
                  style={{ width: 400 }}
                  allowClear
                />
                <Button icon={<ReloadOutlined />} disabled={isLoading} onClick={handleRefresh}>
                  Refresh
                </Button>
                <Switch defaultChecked onChange={handleOnChange} checkedChildren="Active Only" unCheckedChildren="All" />
                <LookupFilter
                  disabled={isLoading}
                  onSelect={(values) => {
                    setPermitTypes(values);
                  }}
                  lookupType={"permittype"}
                  value={permitTypes}
                  onClear={() => setPermitTypes([])}
                  onSelectAll={() => setPermitTypes(lookups?.find((lookup: any) => lookup.map === "permittype")?.options?.map((options: any) => options.value))}
                />
              </Space>
            )}

            <span style={{ display: "flex", alignItems: "center" }}>
              <span style={{ paddingRight: 16 }}>Total: {totalItemCount ?? "-"}</span>
              {showAddWaterRight && (selectedCompany?.userRole === UserRole.admin.label || selectedCompany?._companyId === "all") ? (
                <>
                  <Button icon={<PlusOutlined />} onClick={handleAddWaterRight} type="primary">
                    Add Water Right
                  </Button>
                </>
              ) : (
                <></>
              )}
            </span>
          </div>
        }
      >
        {showAddWaterRight ? <PrintPage content={renderContent()} /> : renderContent()}
      </Card>
      <AddSelectCompanyModal entityName={EnitityName.WaterRight} ref={companySelectModalRef} onSubmit={handleCompanySelectSuccess} />
      {waterRightTypeModalState.open && <SelectWaterRightTypeModal open={waterRightTypeModalState.open} onSuccess={handleSelectWaterRightTypeSuccess} onCancel={handleSelectWaterRightTypeCancel} />}
      {dwrWaterRightModalState.open && <DWRSearchWaterRightModal open={dwrWaterRightModalState.open} onSuccess={handleDwrModalStateSuccess} onCancel={handleDwrModalStateCancel} />}
      {/* {dwrWaterRightModalState.open && <DWRSearchWaterRightModal open={dwrWaterRightModalState.open} onSuccess={handleDwrModalStateSuccess} onCancel={handleDwrModalStateCancel} />} */}
    </>
  );
};

export default memo(WaterRightTable);
