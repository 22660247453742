import { FieldSelector, GroupingSelector, RestrictionSelector, StockSelector, WaterRightReportSelector, WellSelector } from "@/components";
import { constants } from "@/configs";
import useFieldReportLookup from "@/queries/useFieldReportLookup";
import useGroupingLookupQuery from "@/queries/useGroupingLookupQuery";
import useRestrictionReportLookupQuery from "@/queries/useRestrictionReportLookupQuery";
import useStockLookupQuery from "@/queries/useStockLookupQuery";
import useWaterRightReportLookupQuery from "@/queries/useWaterRightReportLookupQuery";
import useWellReportLookupQuery from "@/queries/useWellReportLookupQuery";
import { CloseOutlined, ThunderboltOutlined } from "@ant-design/icons";
import { Button, Card, DatePicker, Divider, Form, Select, Space } from "antd";
import { useWatch } from "antd/es/form/Form";
import dayjs from "dayjs";
import { FC, useEffect, useState } from "react";
import { useSelector } from "react-redux";

import GeneratedAllConfigurationReport from "./GeneratedAllConfigurationReport";
import posthog from "posthog-js";

const AllConfigurationReport: FC = () => {
  const { lookups } = useSelector((state: any) => state.lookups);
  const { selectedCompanyId } = useSelector((state: any) => state.company);

  const [form] = Form.useForm();

  const [generatingReport, setGeneratingReport] = useState<boolean>(false);

  const [stock, setStocks] = useState<any[]>([]);
  const [waterRights, setWaterRights] = useState<any[]>([]);
  const [restrictions, setRestrictions] = useState<any[]>([]);
  const [wells, setWells] = useState<any[]>([]);
  const [fields, setFields] = useState<any[]>([]);
  const [groupings, setGroupings] = useState<any[]>([]);

  const [reportConfig, setReportConfig] = useState<any>(undefined);

  const wellIds: any[] = useWatch("wellIds", form);
  const stockIds: any[] = useWatch("stockIds", form);
  const groupingIds: any[] = useWatch("groupingIds", form);
  const fieldIds: any[] = useWatch("fieldIds", form);
  const waterRightIds: any[] = useWatch("waterRightIds", form);
  const restrictionIds: any[] = useWatch("restrictionIds", form);
  const permitTypes: any[] = useWatch("permitTypes", form);
  const priorityTypes: any[] = useWatch("priorityTypes", form);
  const date: any = useWatch("date", form);

  const [wellIsActive, setWellIsActive] = useState<boolean | null>(true);
  const [waterRightIsActive, setWaterRightIsActive] = useState<boolean | null>(true);
  const [restrictionIsActive, setRestrictionIsActive] = useState<boolean | null>(true);
  const [fieldIsActive, setFieldIsActive] = useState<boolean | null>(true);

  const { groupingLookup } = useGroupingLookupQuery();
  const { fieldLookup } = useFieldReportLookup({ isActive: false });
  const { wellLookup } = useWellReportLookupQuery({ isActive: false });
  const { waterRightLookup } = useWaterRightReportLookupQuery({ isActive: false });
  const { restrictionLookup } = useRestrictionReportLookupQuery({ isActive: false });
  const { stockLookup } = useStockLookupQuery({ searchString: undefined });

  useEffect(() => {
    handleReset();
    // eslint-disable-next-line
  }, [selectedCompanyId]);

  useEffect(() => {
    if (generatingReport) setTimeout(() => setGeneratingReport(false), 500);
    // eslint-disable-next-line
  }, [generatingReport]);

  const handleGenerateReport = () => {
    setGeneratingReport(true);

    posthog.capture("generate_report", {
      reportType: "All Configuration Report",
    });

    const reportConfig: any = {
      groupingIds: undefined,
      groupings: "-",
      fieldIds: undefined,
      fields: "-",
      wellIds: undefined,
      wells: "-",
      waterRightIds: undefined,
      waterRights: "-",
      restrictionIds: undefined,
      restrictions: "-",
      stockIds: undefined,
      stock: "-",
      permitTypes: "-",
      priorityTypes: "-",
      generatedDate: dayjs().format(constants.dateTimeFormatWithSeconds),
      year: date?.year(),
      hasAdditionalFilters: false,
      wellActive: wellIsActive,
      waterRightActive: waterRightIsActive,
      restrictionActive: restrictionIsActive,
      fieldActive: fieldIsActive,
    };

    //Nothing selected
    if (
      (groupingIds?.length ?? 0) === 0 &&
      (fieldIds?.length ?? 0) === 0 &&
      (wellIds?.length ?? 0) === 0 &&
      (waterRightIds?.length ?? 0) === 0 &&
      (restrictionIds?.length ?? 0) === 0 &&
      (stockIds?.length ?? 0) === 0 &&
      (permitTypes?.length ?? 0) === 0 &&
      (priorityTypes?.length ?? 0) === 0
    ) {
      setReportConfig(reportConfig);
      return;
    }

    reportConfig.hasAdditionalFilters = true;

    const groupingsMap = new Set();
    const fieldsMap = new Set();
    const wellsMap = new Set();
    const waterRightsMap = new Set();
    const restrictionsMap = new Set();
    const stockMap = new Set();

    if (groupingIds?.length > 0) {
      groupingIds.forEach((groupingId) => {
        groupingsMap.add(groupingId);
        const grouping = groupingLookup.find((grouping) => grouping.id === groupingId);
        grouping?.waterRights?.forEach((waterRightId: string) => {
          waterRightsMap.add(waterRightId);
          const wells = wellLookup.filter((well) => well.waterRightId === waterRightId);
          wells.forEach((well) => {
            wellsMap.add(well.id);
            fieldsMap.add(well.fieldId);
          });

          const stock = stockLookup.filter((stock) => stock.waterRightIds?.includes(waterRightId));
          stock?.forEach((stock) => stockMap.add(stock.id));

          const restriction = restrictionLookup.filter((restriction) => restriction.waterRightIds?.includes(waterRightId));
          restriction?.forEach((restriction) => restrictionsMap.add(restriction.id));
        });
      });
    }

    if (fieldIds?.length > 0) {
      fieldIds.forEach((fieldId) => {
        fieldsMap.add(fieldId);
        const wells = wellLookup.filter((well) => well.fieldId === fieldId);
        wells.forEach((well) => {
          wellsMap.add(well.id);
          waterRightsMap.add(well.waterRightId);
          const stock = stockLookup.filter((stock) => stock.waterRightIds?.includes(well.waterRightId));
          stock?.forEach((stock) => stockMap.add(stock.id));

          const restriction = restrictionLookup.filter((restriction) => restriction.waterRightIds?.includes(well.waterRightId));
          restriction?.forEach((restriction) => restrictionsMap.add(restriction.id));
        });
      });
    }

    if (wellIds?.length > 0) {
      wellIds.forEach((wellId) => {
        wellsMap.add(wellId);
        const well = wellLookup.find((well) => well.id === wellId);
        fieldsMap.add(well?.fieldId);
        waterRightsMap.add(well?.waterRightId);

        const grouping = groupingLookup.filter((grouping) => grouping.waterRights?.includes(well?.waterRightId));
        grouping?.forEach((grouping) => groupingsMap.add(grouping.id));

        const stock = stockLookup.filter((stock) => stock.waterRightIds?.includes(well?.waterRightId));
        stock?.forEach((stock) => stockMap.add(stock.id));

        const restriction = restrictionLookup.filter((restriction) => restriction.waterRightIds?.includes(well?.waterRightId));
        restriction?.forEach((restriction) => restrictionsMap.add(restriction.id));
      });
    }

    if (waterRightIds?.length > 0) {
      waterRightIds.forEach((waterRightId) => {
        waterRightsMap.add(waterRightId);
        const wells = wellLookup.filter((well) => well.waterRightId === waterRightId);
        wells.forEach((well) => {
          wellsMap.add(well.id);
          fieldsMap.add(well.fieldId);
        });

        const grouping = groupingLookup.filter((grouping) => grouping.waterRights?.includes(waterRightId));
        grouping?.forEach((grouping) => groupingsMap.add(grouping.id));

        const stock = stockLookup.filter((stock) => stock.waterRightIds?.includes(waterRightId));
        stock?.forEach((stock) => stockMap.add(stock.id));

        const restriction = restrictionLookup.filter((restriction) => restriction.waterRightIds?.includes(waterRightId));
        restriction?.forEach((restriction) => restrictionsMap.add(restriction.id));
      });
    }

    if (restrictionIds?.length > 0) {
      restrictionIds.forEach((restrictionId) => {
        restrictionsMap.add(restrictionId);
        const restriction = restrictionLookup.find((restriction) => restriction.id === restrictionId);
        restriction?.waterRightIds?.forEach((waterRightId: string) => {
          waterRightsMap.add(waterRightId);
          const wells = wellLookup.filter((well) => well.waterRightId === waterRightId);
          wells.forEach((well) => {
            wellsMap.add(well.id);
            fieldsMap.add(well.fieldId);
          });

          const grouping = groupingLookup.filter((grouping) => grouping.waterRights?.includes(waterRightId));
          grouping?.forEach((grouping) => groupingsMap.add(grouping.id));

          const stock = stockLookup.filter((stock) => stock.waterRightIds?.includes(waterRightId));
          stock?.forEach((stock) => stockMap.add(stock.id));
        });
      });
    }

    if (stockIds?.length > 0) {
      stockIds.forEach((stockId) => {
        stockMap.add(stockId);
        const stock = stockLookup.find((stock) => stock.id === stockId);
        stock?.waterRightIds?.forEach((waterRightId: string) => {
          waterRightsMap.add(waterRightId);
          const wells = wellLookup.filter((well) => well.waterRightId === waterRightId);
          wells.forEach((well) => {
            wellsMap.add(well.id);
            fieldsMap.add(well.fieldId);
          });

          const grouping = groupingLookup.filter((grouping) => grouping.waterRights?.includes(waterRightId));
          grouping?.forEach((grouping) => groupingsMap.add(grouping.id));

          const restriction = restrictionLookup.filter((restriction) => restriction.waterRightIds?.includes(waterRightId));
          restriction?.forEach((restriction) => restrictionsMap.add(restriction.id));
        });
      });
    }

    if (permitTypes?.length > 0) {
      waterRightLookup.forEach((waterRight) => {
        if (permitTypes.includes(waterRight.permitType)) {
          waterRightsMap.add(waterRight.id);
          const wells = wellLookup.filter((well) => well.waterRightId === waterRight.id);
          wells.forEach((well) => {
            wellsMap.add(well.id);
            fieldsMap.add(well.fieldId);
          });

          const grouping = groupingLookup.filter((grouping) => grouping.waterRights?.includes(waterRight.id));
          grouping?.forEach((grouping) => groupingsMap.add(grouping.id));

          const stock = stockLookup.filter((stock) => stock.waterRightIds?.includes(waterRight.id));
          stock?.forEach((stock) => stockMap.add(stock.id));

          const restriction = restrictionLookup.filter((restriction) => restriction.waterRightIds?.includes(waterRight.id));
          restriction?.forEach((restriction) => restrictionsMap.add(restriction.id));
        }
      });
    }

    if (priorityTypes?.length > 0) {
      waterRightLookup.forEach((waterRight) => {
        if (priorityTypes.includes(waterRight.priorityType)) {
          waterRightsMap.add(waterRight.id);
          const wells = wellLookup.filter((well) => well.waterRightId === waterRight.id);
          wells.forEach((well) => {
            wellsMap.add(well.id);
            fieldsMap.add(well.fieldId);
          });

          const grouping = groupingLookup.filter((grouping) => grouping.waterRights?.includes(waterRight.id));
          grouping?.forEach((grouping) => groupingsMap.add(grouping.id));

          const stock = stockLookup.filter((stock) => stock.waterRightIds?.includes(waterRight.id));
          stock?.forEach((stock) => stockMap.add(stock.id));

          const restriction = restrictionLookup.filter((restriction) => restriction.waterRightIds?.includes(waterRight.id));
          restriction?.forEach((restriction) => restrictionsMap.add(restriction.id));
        }
      });
    }

    reportConfig.groupingIds = Array.from(groupingsMap);
    reportConfig.groupings = groupingLookup
      .filter((grouping) => reportConfig.groupingIds?.includes(grouping.id))
      .map((grouping) => grouping.name)
      .join(", ");
    reportConfig.fieldIds = Array.from(fieldsMap);
    reportConfig.fields = fieldLookup
      .filter((field) => reportConfig.fieldIds?.includes(field.id))
      .map((field) => field.name)
      .join(", ");
    reportConfig.wellIds = Array.from(wellsMap);
    reportConfig.wells = wellLookup
      .filter((well) => reportConfig.wellIds?.includes(well.id))
      .map((well) => well.name)
      .join(", ");
    reportConfig.waterRightIds = Array.from(waterRightsMap);
    reportConfig.waterRights = waterRightLookup
      .filter((waterRight) => reportConfig.waterRightIds?.includes(waterRight.id))
      .map((waterRight) => waterRight.fileNumber)
      .join(", ");
    reportConfig.restrictionIds = Array.from(restrictionsMap);
    reportConfig.restrictions = restrictionLookup
      .filter((restriction) => reportConfig.restrictionIds?.includes(restriction.id))
      .map((restriction) => restriction.name)
      .join(", ");
    reportConfig.stockIds = Array.from(stockMap);
    reportConfig.stock = stockLookup
      .filter((stock) => reportConfig.stockIds?.includes(stock.id))
      .map((stock) => stock.name)
      .join(", ");

    const permitTypeLookups = lookups?.find((lookup: any) => lookup.map === "permittype");
    const priorityTypeLookups = lookups?.find((lookup: any) => lookup.map === "prioritytype");

    reportConfig.permitTypes = permitTypeLookups.options
      .filter((option: any) => permitTypes?.includes(option.value))
      .map((option: any) => option.name)
      .join(", ");
    reportConfig.priorityTypes = priorityTypeLookups.options
      .filter((option: any) => priorityTypes?.includes(option.value))
      .map((option: any) => option.name)
      .join(", ");

    setReportConfig(reportConfig);
  };

  const handleReset = () => {
    form?.resetFields();
    setReportConfig(undefined);
  };

  const renderActionsButtons = () => {
    return (
      <Space>
        <Button loading={generatingReport} disabled={generatingReport} type="primary" icon={<ThunderboltOutlined />} onClick={handleGenerateReport}>
          Generate Report
        </Button>
        <Button loading={generatingReport} disabled={generatingReport} icon={<CloseOutlined />} onClick={handleReset}>
          Reset
        </Button>
      </Space>
    );
  };

  return (
    <>
      <Card title="Configuration" actions={[renderActionsButtons()]} bodyStyle={{ padding: 12, margin: 0 }}>
        <Form form={form} labelCol={{ span: 6 }} wrapperCol={{ span: 14 }} autoComplete="off" style={{ margin: 0 }}>
          <Form.Item label="Year" name="date" rules={[{ required: true, message: "Please enter a year" }]} style={{ margin: 0, marginBottom: 10 }} initialValue={dayjs()}>
            <DatePicker picker="year" style={{ marginRight: 30 }} placeholder="All Readings" allowClear={false} />
          </Form.Item>
          <GroupingSelector
            form={form}
            propertyToSet={"groupingIds"}
            label={"Groupings"}
            setGroupings={setGroupings}
            style={{ margin: 0, marginBottom: 10 }}
            placeholder="Leave blank to select all groupings"
          />
          <FieldSelector
            setActive={setFieldIsActive}
            form={form}
            propertyToSet={"fieldIds"}
            label={"Fields"}
            placeholder="Leave blank to select all fields"
            style={{ margin: 0, marginBottom: 10 }}
            setFields={setFields}
          />
          <WellSelector
            setActive={setWellIsActive}
            form={form}
            propertyToSet={"wellIds"}
            label={"Wells"}
            placeholder="Leave blank to select all wells"
            style={{ margin: 0, marginBottom: 10 }}
            setWells={setWells}
          />
          <WaterRightReportSelector
            setActive={setWaterRightIsActive}
            form={form}
            propertyToSet={"waterRightIds"}
            label={"Water Rights"}
            setWaterRights={setWaterRights}
            placeholder="Leave blank to select all water rights"
            style={{ margin: 0, marginBottom: 10 }}
          />
          <RestrictionSelector
            setActive={setRestrictionIsActive}
            form={form}
            propertyToSet={"restrictionIds"}
            label={"Restrictions"}
            placeholder="Leave blank to select all restrictions"
            style={{ margin: 0, marginBottom: 10 }}
            setRestrictions={setRestrictions}
          />
          <StockSelector form={form} propertyToSet={"stockIds"} label={"Stock"} placeholder="Leave blank to select all stocks" style={{ margin: 0, marginBottom: 0 }} setStocks={setStocks} />
          <Form.Item label="Permit Type" name="permitTypes" style={{ margin: 0, paddingTop: 10 }}>
            <Select
              allowClear
              mode="multiple"
              loading={generatingReport}
              placeholder="Leave blank to select all permit types"
              showSearch
              optionFilterProp="label"
              maxTagCount={3}
              disabled={generatingReport}
              dropdownRender={(menu) => (
                <>
                  {menu}
                  <Divider style={{ margin: "8px 0" }} />
                  <Space style={{ padding: "0 8px 4px" }}>
                    <Button
                      onClick={() =>
                        form.setFieldValue(
                          "permitTypes",
                          lookups.find((lookup: any) => lookup.map === "permittype").options.map((lookup: any) => lookup.value)
                        )
                      }
                    >
                      Select All
                    </Button>
                    <Button danger onClick={() => form.setFieldValue("permitTypes", [])}>
                      Clear List
                    </Button>
                  </Space>
                </>
              )}
            >
              {lookups
                .find((lookup: any) => lookup.map === "permittype")
                .options.map((lookup: any) => {
                  return (
                    <Select.Option value={lookup.value} key={lookup.id} label={lookup.name}>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          justifyContent: "space-between",
                        }}
                      >
                        {lookup.name}
                      </div>
                    </Select.Option>
                  );
                })}
            </Select>
          </Form.Item>
          <Form.Item label="Priority Type" name="priorityTypes" style={{ margin: 0, paddingTop: 10 }}>
            <Select
              allowClear
              mode="multiple"
              loading={generatingReport}
              placeholder="Leave blank to select all priority types"
              showSearch
              optionFilterProp="label"
              maxTagCount={3}
              disabled={generatingReport}
              dropdownRender={(menu) => (
                <>
                  {menu}
                  <Divider style={{ margin: "8px 0" }} />
                  <Space style={{ padding: "0 8px 4px" }}>
                    <Button
                      onClick={() =>
                        form.setFieldValue(
                          "priorityTypes",
                          lookups.find((lookup: any) => lookup.map === "prioritytype").options.map((lookup: any) => lookup.value)
                        )
                      }
                    >
                      Select All
                    </Button>
                    <Button danger onClick={() => form.setFieldValue("priorityTypes", [])}>
                      Clear List
                    </Button>
                  </Space>
                </>
              )}
            >
              {lookups
                .find((lookup: any) => lookup.map === "prioritytype")
                .options.map((lookup: any) => {
                  return (
                    <Select.Option value={lookup.value} key={lookup.id} label={lookup.name}>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          justifyContent: "space-between",
                        }}
                      >
                        {lookup.name}
                      </div>
                    </Select.Option>
                  );
                })}
            </Select>
          </Form.Item>
        </Form>
      </Card>
      {generatingReport && (
        <Card title="Generating Report">
          <div style={{ textAlign: "center" }}>Loading...</div>
        </Card>
      )}
      {!generatingReport && reportConfig && <GeneratedAllConfigurationReport reportConfig={reportConfig} />}
    </>
  );
};

export default AllConfigurationReport;
