import defaultQueryConfig from "@/configs/defaultQueryConfig";
import CacheKeys from "@/types/CacheKeys";
import { useQuery } from "@tanstack/react-query";
import posthog from "posthog-js";
import { useCallback } from "react";

export enum FeatureFlagType {
  ANALYTICS_DASHBOARD = "ANALYTICS_DASHBOARD",
  IMPERSONATION = "IMPERSONATION",
  PRINT_PAGE = "PRINT_PAGE",
  DWR_INTEGRATION = "DWR_INTEGRATION",
  APPEARENCE = "APPEARENCE",
  DASHBOARD_ORDERS = "DASHBOARD_ORDERS",
}

function useFeatureFlag(feature: FeatureFlagType) {
  const hasFeatureFlag = useCallback(() => {
    if (!posthog) return false;
    const hasFeature = posthog.isFeatureEnabled(feature);
    return hasFeature;
  }, [feature]);

  const { data: hasFeature } = useQuery({
    queryKey: [CacheKeys.featureFlag, feature],
    queryFn: hasFeatureFlag,
    ...defaultQueryConfig,
    enabled: !!feature,
    staleTime: Infinity,
    gcTime: Infinity,
  });

  return {
    hasFeature,
  };
}

export default useFeatureFlag;
