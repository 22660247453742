import { enable2FaPhoneNumber, remove2FaPhoneNumber, updateUserProfile } from "@/apis/identity.api";
import { checkBillableRequired } from "@/apis/well.api";
import { DashboardOrdersSection, DeviceTable } from "@/components";
import TwoFactorModal from "@/components/TwoFactorModal/TwoFactorModal";
import { constants } from "@/configs";
import useProfile from "@/queries/useProfile";
import useDashboardOrders from "@/services/useDashboardOrders";
import useFeatureFlag, { FeatureFlagType } from "@/services/useFeatureFlag";
import { emailValidator } from "@/utils/isValidEmail";
import { CheckCircleOutlined, CloseCircleOutlined, CloseOutlined, ThunderboltOutlined } from "@ant-design/icons";
import { Alert, Button, Divider, Form, Input, Modal, Popconfirm, Popover, Select, Space, Tabs, message, notification } from "antd";
import { MaskedInput } from "antd-mask-input";
import { FC, useEffect, useState } from "react";
import { useAuth } from "react-oidc-context";
import { useSelector } from "react-redux";

import "./ProfileUpdateModal.scss";

interface Props {
  open: boolean;
  onSuccess: (closeModal: boolean) => void;
  onCancel: () => void;
  activeTab?: string;
}

const availableCountryCodes = ["+1", "+27"];

const ProfileUpdateModal: FC<Props> = (props) => {
  const { open, onSuccess, onCancel, activeTab } = props;

  const { selectedCompanyId, selectedCompany } = useSelector((state: any) => state.company);

  const { dashboardOrders, setDashboardOrders } = useDashboardOrders();

  const { hasFeature: hasAppearenceTab } = useFeatureFlag(FeatureFlagType.APPEARENCE);

  const { hasFeature: hasDashboardOrders } = useFeatureFlag(FeatureFlagType.DASHBOARD_ORDERS);

  const [data, setData] = useState<any>(undefined);
  const [disabled, setDisabled] = useState(true);
  const [form] = Form.useForm();
  const [error, setError] = useState<any>(undefined);
  const auth = useAuth();
  const [userId, setUserId] = useState<any>(null);
  const [tabId, setTabId] = useState<string>("profile");
  const [billableRequired, setBillableRequired] = useState<boolean>(false);
  const [loginWithPhoneNumberPressed, setLoginWithPhoneNumberPressed] = useState<boolean>(false);

  const [twoFactorModalState, setTwoFactorModalState] = useState<any>({
    open: false,
  });

  const [loadingbillableRequired, setLoadingBillableRequired] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [loading2Fa, setLoading2Fa] = useState<boolean>(false);

  const [order, setOrder] = useState<string[]>(dashboardOrders ?? []);
  const [defaultKey, setDefaultKey] = useState<string>(dashboardOrders.find((orders: any) => orders.default === true)?.value ?? "operations");

  const [api, contextHolder] = notification.useNotification();

  useEffect(() => {
    if (activeTab) setTabId(activeTab);
    // eslint-disable-next-line
  }, [activeTab]);

  useEffect(() => {
    if (auth?.user?.access_token) {
      populateUserData();
      setUserId(auth?.user?.profile?.sub);
    }
    // eslint-disable-next-line
  }, [auth.user]);

  useEffect(() => {
    if (data) {
      form.setFieldsValue(data);
      form.resetFields();
      setDisabled(false);
    }
    // eslint-disable-next-line
  }, [tabId, data, form]);

  useEffect(() => {
    getBillableRequired();
    // eslint-disable-next-line
  }, [selectedCompanyId]);

  const handleSubmit = () => {
    form.validateFields().then((values) => {
      handleProfileUpdateSubmitData(values, false);
    });
  };

  const getBillableRequired = async () => {
    setLoadingBillableRequired(true);

    const response = await checkBillableRequired({
      companyId: selectedCompanyId,
    });
    if (response.ok) {
      const data = await response.json();
      setBillableRequired(data.value);
    }

    setLoadingBillableRequired(false);
  };

  const populateUserData = async () => {
    if (auth.isAuthenticated) {
      const response = await fetch(`${constants.oidcConfig.authority}/api/user`, {
        headers: { Authorization: `Bearer ${auth.user!.access_token}` },
      });

      const data = await response.json();

      setData({
        phoneNumberConfirmed: data?.phoneNumberConfirmed,
        billingEmails: data?.billingEmails !== null ? data?.billingEmails : undefined,
        firstName: data?.firstName,
        lastName: data?.lastName,
        email: data?.email,
        phoneNumber: data?.phoneNumber,
        addressLine1: data?.addressLine1,
        addressLine2: data?.addressLine2,
        city: data?.city,
        state: data?.state,
        zipCode: data?.zipCode,
        country: data?.country?.length > 0 && data?.country !== null ? data?.country : "United States",
        countryCode: data?.countryCode,
      });
    }
  };

  const handleProfileUpdateSubmitData = async (data: any, closeModal: boolean) => {
    setLoading(true);

    const updatedOrders = order.map((item: any) => ({
      value: item,
      default: item === defaultKey,
    }));

    setDashboardOrders(updatedOrders);

    //Clean Phone Number
    if (data.phoneNumber?.includes("_")) data.phoneNumber = null;
    else data.phoneNumber = data?.phoneNumber?.replace(/-/g, "");
    if (data.billingEmails?.length === 0 || data.billingEmails === undefined) data.billingEmails = null;

    const response = await updateUserProfile(data);

    if (response.ok) {
      invalidateProfile();
      onSuccess && onSuccess(closeModal);
    } else {
      setError("An error occured");
    }
    setLoading(false);
  };

  const prefixSelector = (
    <Form.Item initialValue={data?.countryCode ?? "+1"} name="countryCode" noStyle>
      <Select disabled={data?.phoneNumberConfirmed ? true : false} style={{ width: 70 }}>
        {availableCountryCodes.map((code: string) => (
          <Select.Option value={code} label={code}>
            {code}
          </Select.Option>
        ))}
      </Select>
    </Form.Item>
  );

  const enable2FaForPhoneNumber = async () => {
    setLoading2Fa(true);

    setLoginWithPhoneNumberPressed(true);
    let errorList = null;
    const values = await form
      .validateFields()
      .then(async (values) => {
        const phoneNumber = values?.phoneNumber?.replace(/-/g, "");
        if (phoneNumber !== data?.phoneNumber) await handleProfileUpdateSubmitData({ ...values, phoneNumber }, true);
      })
      .catch((errors) => (errorList = errors));

    if (!values?.errorFields) {
      const response = await enable2FaPhoneNumber();

      if (response.ok) {
        const data = await response.json();
        setTwoFactorModalState({
          open: true,
          expirationDate: data.expirationDate,
          validationCode: data.validationCode,
        });
        invalidateProfile();
      } else {
        const key = `open${Date.now()}`;
        const btn = (
          <Space>
            <Button type="default" onClick={() => api.destroy(key)}>
              Close
            </Button>
          </Space>
        );

        api.error({
          message: "Failed to enable login with phone number",
          description: await response.text(),
          btn,
          key,
          duration: 0,
          placement: "top",
          style: { width: 450 },
        });
      }
    }

    setLoading2Fa(false);
  };

  const { invalidateProfile } = useProfile();

  const handleDisable2Fa = async () => {
    setLoading2Fa(true);

    const response = await remove2FaPhoneNumber();
    if (response.ok) {
      invalidateProfile();
      populateUserData();
      message.success("Successfully removed login with phone number");
    } else {
      const key = `open${Date.now()}`;
      const btn = (
        <Space>
          <Button type="default" onClick={() => api.destroy(key)}>
            Close
          </Button>
        </Space>
      );

      api.error({
        message: "Error removing login with phone number",
        description: "Error occured during removing login with phone number",
        btn,
        key,
        duration: 0,
        placement: "top",
        style: { width: 450 },
      });
    }

    setLoading2Fa(false);
  };

  const renderTabs = () => {
    const tabs: any[] = [
      {
        label: "Profile",
        key: "profile",
        children: (
          <Form id="profileUpdateModal" form={form} layout="horizontal" name="form_in_modal" labelAlign="left" labelCol={{ span: 6, offset: 3 }}>
            <Divider orientation="left">Details</Divider>
            <Form.Item initialValue={data?.firstName} name="firstName" label="First Name">
              <Input disabled={disabled} placeholder="First Name" />
            </Form.Item>

            <Form.Item initialValue={data?.lastName} name="lastName" label="Last Name">
              <Input disabled={disabled} placeholder="Last Name" />
            </Form.Item>

            <Form.Item initialValue={data?.email} name="email" label="Email">
              <Input disabled placeholder="Email" />
            </Form.Item>

            <Form.Item
              initialValue={data?.phoneNumber}
              name="phoneNumber"
              label="Phone Number"
              rules={[
                {
                  message: "Enter phone number to verify login with phone number",
                  validator: (_, value: string) => {
                    if (loginWithPhoneNumberPressed) {
                      if (value.includes("_")) {
                        setLoginWithPhoneNumberPressed(false);
                        return Promise.reject();
                      } else {
                        setLoginWithPhoneNumberPressed(false);
                        return Promise.resolve();
                      }
                    } else {
                      return Promise.resolve();
                    }
                  },
                },
              ]}
            >
              <MaskedInput
                disabled={data?.phoneNumberConfirmed ? true : false}
                addonBefore={prefixSelector}
                mask={"000-000-0000"}
                suffix={
                  <Popover
                    // title='Enable login with phone number'
                    content={
                      <div>
                        <p>Login with phone number is {data?.phoneNumberConfirmed ? "enabled" : "disabled"}</p>
                      </div>
                    }
                    trigger={"hover"}
                    placement="top"
                  >
                    {data?.phoneNumberConfirmed ? <CheckCircleOutlined style={{ color: "green" }} /> : <CloseCircleOutlined style={{ color: "red" }} />}
                  </Popover>
                }
              />
            </Form.Item>

            <Form.Item wrapperCol={{ offset: 9 }}>
              {data?.phoneNumberConfirmed ? (
                <Popconfirm
                  title="Disable Login With Phone Number"
                  description={
                    <>
                      You will no longer be able to login using your phone number. <br />
                      Are you sure you want to disable login with phone number?
                    </>
                  }
                  okText="Yes"
                  cancelText="No"
                  onConfirm={handleDisable2Fa}
                >
                  <Button danger loading={loading2Fa}>
                    Disable Login With Phone Number
                  </Button>
                </Popconfirm>
              ) : (
                <>
                  By clicking the "Enable Login with Phone Number" button you agree that your phone number may be used for verification purposes. <br />
                  <br />
                  <Button type="primary" loading={loading2Fa} onClick={enable2FaForPhoneNumber}>
                    Enable Login with Phone Number
                  </Button>
                </>
              )}
            </Form.Item>

            <Divider orientation="left">Billing Address</Divider>

            <Form.Item
              name="billingEmails"
              label="Billing Emails"
              rules={[
                {
                  message: "An invalid email was entered.",
                  validator: emailValidator,
                },
              ]}
            >
              <Select mode="tags" style={{ width: "100%" }} placeholder="Billing Emails" />
            </Form.Item>

            <Form.Item initialValue={data?.addressLine1} name="addressLine1" label="Address Line 1" rules={billableRequired ? [{ required: true, message: "Please enter address line 1" }] : undefined}>
              <Input disabled={disabled} placeholder="Address Line 1" />
            </Form.Item>

            <Form.Item
              initialValue={data?.addressLine2}
              name="addressLine2"
              label="Address Line 2"
              // rules={billableRequired ? [{ required: true, message: 'Please enter address line 2' }] : undefined}
            >
              <Input disabled={disabled} placeholder="Address Line 2" />
            </Form.Item>

            <Form.Item initialValue={data?.city} name="city" label="City" rules={billableRequired ? [{ required: true, message: "Please enter city" }] : undefined}>
              <Input disabled={disabled} placeholder="City" />
            </Form.Item>

            <Form.Item initialValue={data?.state} name="state" label="State" rules={billableRequired ? [{ required: true, message: "Please enter state" }] : undefined}>
              <Input disabled={disabled} placeholder="State" />
            </Form.Item>

            <Form.Item initialValue={data?.zipCode} name="zipCode" label="Zip Code" rules={billableRequired ? [{ required: true, message: "Please enter zip code" }] : undefined}>
              <Input disabled={disabled} placeholder="Zip Code" />
            </Form.Item>

            <Form.Item
              initialValue={data?.country !== "" ? data?.country : "United States"}
              name="country"
              label="Country"
              rules={billableRequired ? [{ required: true, message: "Please enter country" }] : undefined}
            >
              <Input disabled={disabled} placeholder="Country" />
            </Form.Item>

            {error && (
              <Form.Item>
                <Alert type="error" showIcon description={error} />
              </Form.Item>
            )}
          </Form>
        ),
      },
      {
        label: "Devices",
        key: "devices",
        children: <DeviceTable userId={userId} isProfile />,
      },
    ];

    if (hasDashboardOrders) {
      tabs.push({
        label: "Dashboard Order",
        key: "dashboardOrders",
        children: <DashboardOrdersSection defaultKey={defaultKey} setDefaultKey={setDefaultKey} order={order} setOrder={setOrder} />,
      });
    }

    if (hasAppearenceTab) {
      tabs.push({
        label: "Appearance",
        key: "appearance",
        children: <></>,
      });
    }

    return tabs;
  };

  const twoFactorSuccess = () => {
    populateUserData();
    setTwoFactorModalState({ open: false });
    message.success("Successfully enabled login with phone number.");
  };

  const twoFactorCancel = () => {
    setTwoFactorModalState({ open: false });
  };

  return (
    <>
      {contextHolder}
      <Modal
        className="profileUpdateModal"
        okButtonProps={{
          loading: loading || disabled,
          disabled: loading || disabled,
          icon: <ThunderboltOutlined />,
        }}
        cancelButtonProps={{ icon: <CloseOutlined /> }}
        open={open}
        footer={
          <Space>
            <Button icon={<CloseOutlined />} onClick={onCancel}>
              Close
            </Button>
            {tabId === "devices" ? undefined : (
              <Button loading={loading || disabled} disabled={loading || disabled} icon={<ThunderboltOutlined />} type="primary" onClick={handleSubmit}>
                Update
              </Button>
            )}
          </Space>
        }
        title="Settings"
        okText="Update"
        closable={false}
        cancelText="Close"
        onCancel={onCancel}
        onOk={handleSubmit}
        width={800}
        maskClosable={false}
      >
        <Tabs onChange={(props: any) => setTabId(props)} tabPosition="left" size="small" activeKey={tabId} style={{ marginBottom: 32 }} items={renderTabs()} />
      </Modal>
      {twoFactorModalState.open && (
        <TwoFactorModal
          expirationDate={twoFactorModalState.expirationDate}
          validationCode={twoFactorModalState.validationCode}
          open={twoFactorModalState.open}
          phoneNumber={data?.phoneNumber ?? form.getFieldValue("phoneNumber")?.replace(/-/g, "")}
          onSuccess={twoFactorSuccess}
          onCancel={twoFactorCancel}
        />
      )}
    </>
  );
};

export default ProfileUpdateModal;
