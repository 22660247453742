import {
  BarsOutlined,
  FileProtectOutlined,
  FundProjectionScreenOutlined,
  HomeOutlined,
  InfoCircleOutlined,
  MailFilled,
  MenuOutlined,
  PhoneFilled,
  PictureOutlined,
  QuestionCircleOutlined,
  ReadOutlined,
  UserOutlined,
} from "@ant-design/icons";
import { Button, Drawer, DrawerProps, Layout, Modal, Space, Spin, Typography, message, notification } from "antd";
import { getCompanies, getSelectedCompany } from "@/apis/company.api";
import { OrderTokensDrawer, ProfileDropdown, RegistrationModal, ServerStatus, SupportQuery } from "@/components";
import { routes } from "@/configs";
import { FC, useEffect, useState } from "react";
import { useAuth } from "react-oidc-context";
import { useSelector } from "react-redux";
import { RootState, useAppDispatch } from "@/stores";
import { saveCompanies, saveSelectedCompany, saveSelectedCompanyId, setBusyEditing, setCanSwitch, setReloadCompanies } from "@/stores/company.store";
import { getFaqs } from "@/apis/communication.api";
import { getLookups } from "@/apis/lookups.api";
import CompanySelector from "@/components/CompanySelector/CompanySelector";
import RedirectHandler from "@/components/RedirectHandler/RedirectHandler";
import RoleSelector from "@/components/RoleSelector/RoleSelector";
import renderMenuItem from "@/components/Sider/MenuItem";
import useCustomNavigate from "@/services/useCustomNavigate";
import useWindowSize from "@/services/useWindowSize";
import { showNavigationWarning } from "@/services/utils";
import { clearBreadcrumbs } from "@/stores/breadcrumbs.store";
import { clearSupportQueryData, saveFaqs } from "@/stores/communication.store";
import { saveLookups } from "@/stores/lookups.store";
import "./Header.scss";
import { MdSupportAgent, MdAdjust } from "react-icons/md";
import useProfile from "@/queries/useProfile";
import posthog from "posthog-js";

const breakpoints = {
  supportSection: 680,
  menuButton: 700,
  login: 1280,
};

const disabledCanSwitchPaths = ["edit", "view", "add"];
const disabledBusyEditPaths = ["edit", "add"];

const Header: FC = () => {
  const { navigate, current, setCurrent } = useCustomNavigate();
  const auth = useAuth();
  const dispatch = useAppDispatch();

  const { companies, selectedCompany, selectedCompanyId, reload, canSwitch, busyEditing, noFilterApplied } = useSelector((state: RootState) => state.company);
  //const { profile } = useSelector((state: any) => state.user);
  const { profile } = useProfile();
  const { selectedRole } = useSelector((state: any) => state.user);

  const [registrationModalState, setRegistrationModalState] = useState<any>({
    data: undefined,
    open: false,
  });
  const [loading, setLoading] = useState(false);
  const [api, contextHolder] = notification.useNotification();
  const [loadingCompanyProfile, setLoadingCompanyProfile] = useState<boolean>(true);
  const [loadingFaqs, setLoadingFaqs] = useState<boolean>(false);

  const [open, setOpen] = useState(false);
  const [placement, setPlacement] = useState<DrawerProps["placement"]>("left");

  const [supportQueryDrawerState, setSupportQueryDrawerState] = useState<any>({
    open: false,
  });
  const [orderTokensDrawerState, setOrderTokensDrawerState] = useState<any>({
    open: false,
  });

  const size = useWindowSize();

  useEffect(() => {
    if (auth?.user?.profile?.sub && setCurrent) {
      console.log("USER CHANGED=> ", auth?.user?.profile);
      setTimeout(() => {
        dispatch(clearBreadcrumbs());
        handleNav(routes.dashboard);
      }, 100);
    }
  }, [auth?.user?.profile?.sub, setCurrent]);

  useEffect(() => {
    if (size.width >= breakpoints.menuButton) setOpen(false);
  }, [size.width]);

  useEffect(() => {
    let end = window.location.pathname.split("/").pop();
    end && disabledCanSwitchPaths.includes(end) ? dispatch(setCanSwitch(false)) : dispatch(setCanSwitch(true));
    end && disabledBusyEditPaths.includes(end) ? dispatch(setBusyEditing(true)) : dispatch(setBusyEditing(false));
  }, [window.location.pathname]);

  useEffect(() => {
    refreshLookupsList();
    reloadFaqs();
    // eslint-disable-next-line
  }, []);

  const reloadSelectedCompany = async (companyId: string) => {
    setLoadingCompanyProfile(true);
    const response = await getSelectedCompany(companyId);
    if (response.ok) {
      const data = await response.json();

      if (data.isSuccess) {
        if (selectedCompany) message.success("Switched to company - " + data.value.companyName);
        dispatch(saveSelectedCompany(data.value));
      }
    }
    setLoadingCompanyProfile(false);
  };

  useEffect(() => {
    if (selectedCompanyId && selectedCompanyId !== selectedCompany?.companyId) {
      reloadSelectedCompany(selectedCompanyId);
    } else if (!selectedCompanyId && companies.length === 1) {
      dispatch(saveSelectedCompanyId(companies[0].id));
    } else if (!selectedCompanyId && !noFilterApplied && companies.length > 1) {
      const defaultSelectedCompanyId = localStorage.getItem("selectedCompanyId");
      if (defaultSelectedCompanyId && companies.find((c: any) => c.id === defaultSelectedCompanyId)) dispatch(saveSelectedCompanyId(defaultSelectedCompanyId));
      else dispatch(saveSelectedCompany({ _companyId: "all" }));
    } else if (selectedCompanyId && noFilterApplied) {
      dispatch(saveSelectedCompany({ _companyId: "all" }));
    } else {
      setLoadingCompanyProfile(false);
    }
  }, [selectedCompanyId, selectedCompany, companies, noFilterApplied]);

  useEffect(() => {
    if (profile || (profile && reload)) reloadCompanies();
  }, [profile, reload]);

  const showSupportDrawer = () => {
    setSupportQueryDrawerState({ open: true });
  };

  const handleSupportQueryCancel = () => {
    setSupportQueryDrawerState({ open: false });
  };

  const showOrderTokensDrawer = () => {
    setOrderTokensDrawerState({ open: true });
  };

  const handleOrderTokensCancel = () => {
    setOrderTokensDrawerState({ open: false });
  };

  const openSupportQueryCompleteNotification = (ref?: any) => {
    handleSupportQueryCancel();
    dispatch(clearSupportQueryData());

    const key = `open${Date.now()}`;
    const btn = (
      <Space>
        <Button type="default" onClick={() => api.destroy(key)}>
          Close
        </Button>
      </Space>
    );
    api.success({
      message: "Support query successfully submitted",
      description: `Please use the reference ${ref} if you have any queries.`,
      btn,
      key,
      duration: 0,
      placement: "top",
    });
  };

  const openOrderTokenCompleteNotification = (ref?: any) => {
    handleOrderTokensCancel();

    const key = `open${Date.now()}`;
    const btn = (
      <Space>
        <Button type="default" onClick={() => api.destroy(key)}>
          Close
        </Button>
      </Space>
    );
    api.success({
      message: "Order Submitted",
      description: (
        <>
          Your order has been submitted. We will get back to you as soon as possible. <br />
          Reference: {ref}
        </>
      ),
      btn,
      key,
      duration: 0,
      placement: "top",
    });
  };

  const refreshLookupsList = async () => {
    setLoading(true);
    const response = await getLookups();
    if (response.ok) {
      const data = await response.json();
      if (data.isSuccess) {
        dispatch(saveLookups(data.value));
      }
    }
    setLoading(false);
  };

  const reloadCompanies = async () => {
    setLoadingCompanyProfile(true);
    let result = await getCompanies(undefined, null);
    if (result.ok) {
      result.json().then((data) => {
        if (data.isSuccess) {
          if (data.value.length > 0) dispatch(saveCompanies(data.value));
          else setLoadingCompanyProfile(false);
          dispatch(setReloadCompanies(false));
        }
      });
    }
  };

  const reloadFaqs = async () => {
    setLoadingFaqs(true);
    const response = await getFaqs();
    if (response.ok) {
      const data = await response.json();
      if (data.isSuccess) {
        dispatch(saveFaqs(data.value));
      }
    }
    setLoadingFaqs(false);
  };

  useEffect(() => {
    if (profile?.id) {
      posthog.identify(profile?.id, {
        email: profile?.email,
        name: profile?.displayName,
      });
    }
  }, [profile?.displayName, profile?.email, profile?.id]);

  useEffect(() => {
    if (profile) {
      if (profile?.roles?.length > 0) navigate(routes.adminDashboard);
      console.log("TOKEN=> ", auth?.user?.access_token);
    }
  }, [auth?.user, profile, auth.isAuthenticated, navigate]);

  useEffect(() => {
    return auth.events.addAccessTokenExpiring(() => {
      //alert("You're about to be signed out due to inactivity. Press continue to stay signed in.");
      auth.signinSilent();
    });
  }, [auth, auth.isAuthenticated, auth.activeNavigator, auth.isLoading, auth.signinRedirect]);

  const openRegistrationCompleteNotification = () => {
    handleRegistrationCancel();

    const key = `open${Date.now()}`;
    const btn = (
      <Space>
        <Button type="default" onClick={() => api.destroy(key)}>
          Close
        </Button>
      </Space>
    );
    api.success({
      message: "Signup success",
      description: "A confirmation email has been sent to your inbox, please follow the instructions in the email to confirm your account.",
      btn,
      key,
      duration: 0,
      placement: "top",
    });
  };

  const getClass = (route: any) => (route.path === current ? "navigationLink active" : "navigationLink");

  const handleNav = (route: any) => {
    setCurrent?.(route.path);
    navigate(route);
  };

  const handleLogin = () => {
    setLoading(true);
    auth
      .signinRedirect()
      .then(() => setLoading(false))
      .catch((e) => setLoading(false));
  };

  const handleRegistrationCancel = () => {
    setRegistrationModalState({
      ...registrationModalState,
      open: false,
      data: undefined,
    });
  };

  const [modal, modalContextHolder] = Modal.useModal();

  const showRedirectModal = (state: string) => {
    modal.info({
      title: `${state === "login" ? "Redirecting to login page..." : "Redirecting to logout page..."}`,
      content: (
        <div style={{ textAlign: "center", height: 80 }}>
          <Spin size="large" style={{ transform: "translate(-10px, 20px)" }} />
        </div>
      ),
      footer: null,
    });
  };

  useEffect(() => {
    if (auth.activeNavigator === "signinRedirect") showRedirectModal("login");
    if (auth.activeNavigator === "signoutRedirect") showRedirectModal("logout");
  }, [auth.activeNavigator]);

  const showDrawer = () => {
    setOpen(true);
  };

  const onClose = () => {
    setOpen(false);
  };

  const renderContactDetailsBanner = () => (
    <div
      style={{
        backgroundColor: "#00007f",
        width: "100%",
        height: "30px",
        display: "flex",
        flexDirection: "row",
        justifyContent: "center",
        alignItems: "center",
        paddingLeft: 54,
        paddingRight: 10,
      }}
    >
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "space-between",
          flex: 1,
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "flex-start",
          }}
        >
          <PhoneFilled style={{ color: "white", verticalAlign: "middle", marginRight: 5 }} /> <p style={{ color: "white", marginRight: 15, verticalAlign: "middle" }}>785.527.9041</p>
          <MailFilled
            style={{
              color: "white",
              verticalAlign: "center",
              marginLeft: 10,
              marginRight: 5,
            }}
          />{" "}
          <p style={{ color: "white" }}>info@vandwater.com</p>
        </div>
        {size.width > breakpoints.supportSection && (
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "flex-start",
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "flex-end",
                cursor: "pointer",
                marginRight: 15,
              }}
              onClick={showOrderTokensDrawer}
            >
              <MdAdjust
                style={{
                  color: "white",
                  verticalAlign: "center",
                  marginRight: 5,
                }}
              />{" "}
              <p style={{ color: "white" }}>Order Tokens</p>
            </div>
            <div
              style={{
                paddingRight: breakpoints.menuButton <= 700 ? 42 : 60,
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "flex-end",
                cursor: "pointer",
              }}
              onClick={showSupportDrawer}
            >
              <MdSupportAgent
                style={{
                  color: "white",
                  verticalAlign: "center",
                  marginLeft: 10,
                  marginRight: 5,
                }}
              />{" "}
              <p style={{ color: "white" }}>Support</p>
            </div>
          </div>
        )}
      </div>
    </div>
  );

  const handleMenuItemClicked = (route: any) => {
    setOpen(false);
    showNavigationWarning(busyEditing, () => handleNav(route));
  };

  const renderLeftHeaderMenu = (id: string, style?: any, extra?: any) => (
    <div id={id} style={style}>
      <Button
        id="navHomeBtn"
        className={getClass(routes.home)}
        type="link"
        onClick={() => {
          setOpen(false);
          showNavigationWarning(busyEditing, () => handleNav(routes.home));
        }}
      >
        Home
      </Button>
      <Button
        id="navContactUsBtn"
        className={getClass(routes.contactUs)}
        type="link"
        onClick={() => {
          setOpen(false);
          showNavigationWarning(busyEditing, () => handleNav(routes.contactUs));
        }}
      >
        Contact Us
      </Button>
      <Button
        id="navAboutUsBtn"
        className={getClass(routes.aboutUs)}
        type="link"
        onClick={() => {
          setOpen(false);
          showNavigationWarning(busyEditing, () => handleNav(routes.aboutUs));
        }}
      >
        About Us
      </Button>
      <Button
        id="navFaqBtn"
        className={getClass(routes.faqs)}
        type="link"
        onClick={() => {
          setOpen(false);
          showNavigationWarning(busyEditing, () => handleNav(routes.faqs));
        }}
      >
        Frequently Asked Questions
      </Button>
      {auth?.isAuthenticated && (
        <>
          <Button
            id="navDashboardBtn"
            className={getClass(selectedRole ? routes.adminDashboard : routes.dashboard)}
            type="link"
            onClick={() => {
              setOpen(false);
              showNavigationWarning(busyEditing, () => {
                if (selectedRole) {
                  dispatch(clearBreadcrumbs());
                  handleNav(routes.adminDashboard);
                } else {
                  dispatch(clearBreadcrumbs());
                  handleNav(routes.dashboard);
                }
              });
            }}
          >
            Dashboard
          </Button>
        </>
      )}
      {extra && extra}
    </div>
  );

  const renderDrawerMenu = () => (
    <Drawer
      title="Menu"
      placement={placement}
      closable={false}
      onClose={onClose}
      open={open}
      key={placement}
      extra={
        <Space>
          <Button onClick={onClose}>Close</Button>
        </Space>
      }
      styles={{
        body: {
          display: "flex",
          flexDirection: "column",
          alignItems: "flex-start",
          padding: 0,
        },
      }}
    >
      {[
        {
          label: "Home",
          key: "home",
          icon: <HomeOutlined />,
          requiresSelectedCompany: false,
          disabled: false,
          onClick: () => handleMenuItemClicked(routes.home),
        },
        {
          label: "Contact Us",
          key: "contactUs",
          icon: <UserOutlined />,
          requiresSelectedCompany: false,
          disabled: false,
          onClick: () => handleMenuItemClicked(routes.contactUs),
        },
        {
          label: "About Us",
          key: "aboutUs",
          icon: <InfoCircleOutlined />,
          requiresSelectedCompany: false,
          disabled: false,
          onClick: () => handleMenuItemClicked(routes.aboutUs),
        },
        {
          label: "Frequently Asked Questions",
          key: "faqs",
          icon: <QuestionCircleOutlined />,
          requiresSelectedCompany: false,
          disabled: false,
          onClick: () => handleMenuItemClicked(routes.faqs),
        },
        {
          label: "Vision and Mission",
          key: "vissionAndMission",
          icon: <BarsOutlined />,
          requiresSelectedCompany: false,
          disabled: false,
          onClick: () => handleMenuItemClicked(routes.missionVision),
        },
        {
          label: "Ethics & Sustainability",
          key: "ethicsAndSustainability",
          icon: <FundProjectionScreenOutlined />,
          requiresSelectedCompany: false,
          disabled: false,
          onClick: () => handleMenuItemClicked(routes.ethicsSustainability),
        },
        {
          label: "Photo Gallery",
          key: "photoGallery",
          icon: <PictureOutlined />,
          requiresSelectedCompany: false,
          disabled: false,
          onClick: () => handleMenuItemClicked(routes.photoGallery),
        },
        {
          label: "Privacy Policy",
          key: "privacyPolicy",
          icon: <FileProtectOutlined />,
          requiresSelectedCompany: false,
          disabled: false,
          onClick: () => handleMenuItemClicked(routes.privacy),
        },
        {
          label: "Terms of Use",
          key: "termsOfUse",
          icon: <ReadOutlined />,
          requiresSelectedCompany: false,
          disabled: false,
          onClick: () => handleMenuItemClicked(routes.termsOfUse),
        },
        {
          label: "Order Tokens",
          key: "orderTokens",
          icon: <MdAdjust />,
          requiresSelectedCompany: false,
          disabled: false,
          onClick: showOrderTokensDrawer,
        },
        {
          label: "Support",
          key: "support",
          icon: <MdSupportAgent />,
          requiresSelectedCompany: false,
          disabled: false,
          onClick: showSupportDrawer,
        },
      ].map((item: any) => renderMenuItem(false, item, undefined, item.position))}
    </Drawer>
  );

  return (
    <>
      {renderContactDetailsBanner()}
      <Layout.Header id="globalHeader">
        <RedirectHandler />
        {contextHolder}
        {modalContextHolder}
        <div id="globalHeaderLogo">
          <img src="/logo.png" alt="logo" style={{ marginTop: 15 }} width={120} height={35} />
        </div>
        {size.width <= breakpoints.menuButton && (
          <div id="rightHeaderMenu">
            <Button id="navLoginBtn" type="link" onClick={() => setOpen(true)} icon={<MenuOutlined />} style={{ marginTop: -8, fontSize: 22, padding: 0 }}>
              Menu
            </Button>
          </div>
        )}
        {size.width <= breakpoints.menuButton && renderDrawerMenu()}
        {size.width > breakpoints.menuButton && renderLeftHeaderMenu("leftHeaderMenu")}
        {size.width > breakpoints.login && (
          <div id="rightHeaderMenu">
            {!auth?.isAuthenticated && (
              <>
                <ServerStatus />
                <Button
                  // loading={loading}
                  id="navLoginBtn"
                  type="link"
                  onClick={handleLogin}
                >
                  Login
                </Button>
                <Button
                  id="navRegisterBtn"
                  type="link"
                  onClick={() =>
                    setRegistrationModalState({
                      ...registrationModalState,
                      open: true,
                    })
                  }
                >
                  Register
                </Button>
              </>
            )}
            {auth?.isAuthenticated && (
              <Space>
                <ServerStatus />
                {profile?.roles?.length > 0 ? <RoleSelector loading={loading} /> : <CompanySelector loading={loadingCompanyProfile} />}
                <Typography style={{ textAlign: "right" }}>
                  {profile ? (
                    <>
                      Hello <b>{profile.displayName}</b>&nbsp;
                    </>
                  ) : (
                    <>Loading profile...&nbsp;</>
                  )}
                </Typography>
                <ProfileDropdown />
              </Space>
            )}
          </div>
        )}
        {registrationModalState.open && <RegistrationModal onSuccess={openRegistrationCompleteNotification} open={registrationModalState.open} onCancel={handleRegistrationCancel} />}
        {supportQueryDrawerState.open && <SupportQuery onSuccess={openSupportQueryCompleteNotification} open={supportQueryDrawerState.open} onCancel={handleSupportQueryCancel} />}
        {orderTokensDrawerState.open && <OrderTokensDrawer onSuccess={openOrderTokenCompleteNotification} open={orderTokensDrawerState.open} onCancel={handleOrderTokensCancel} />}
      </Layout.Header>
    </>
  );
};

export default Header;
