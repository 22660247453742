import { CloseOutlined, ThunderboltOutlined } from "@ant-design/icons";
import { Button, Card, DatePicker, Form, Space } from "antd";
import { useWatch } from "antd/es/form/Form";
import { constants } from "@/configs";
import dayjs from "dayjs";
import { FC, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import GeneratedCompanyTotalUsageReport from "./GeneratedCompanyTotalUsageReport";
import { CompanyReportSelector } from "@/components";
import posthog from "posthog-js";

const { RangePicker } = DatePicker;

const CompanyTotalUsageReport: FC = () => {
  const { selectedCompanyId, selectedCompany } = useSelector((state: any) => state.company);

  const [form] = Form.useForm();

  const [generatingReport, setGeneratingReport] = useState<boolean>(false);
  const [reportConfig, setReportConfig] = useState<any>(undefined);
  const [years, setYears] = useState<any[]>([]);
  const [companies, setCompanies] = useState<any[]>([]);

  const dates: any[] = useWatch("date", form);
  const companyIds: any[] = useWatch("companyIds", form);

  const currentYear = dayjs();
  const previousYear = dayjs().add(-1, "year");

  useEffect(() => {
    handleReset();
    // eslint-disable-next-line
  }, [selectedCompanyId]);

  useEffect(() => {
    setYears(dates?.map((date) => dayjs(date).year()));
    // eslint-disable-next-line
  }, [dates]);

  useEffect(() => {
    if (generatingReport) setTimeout(() => setGeneratingReport(false), 500);
    // eslint-disable-next-line
  }, [generatingReport]);

  const handleGenerateReport = () => {
    setGeneratingReport(true);

    posthog.capture("generate_report", {
      reportType: "Company Total Usage Report",
    });

    setReportConfig({
      companyIds: selectedCompanyId ? [selectedCompanyId] : companyIds,
      companies:
        selectedCompanyId !== undefined && selectedCompanyId
          ? selectedCompany?.companyName
          : companies
              ?.filter((company) => companyIds?.includes(company?.id))
              ?.map((company) => company?.name)
              ?.join(", "),
      // companyId: selectedCompanyId,
      generatedDate: dayjs().format(constants.dateTimeFormatWithSeconds),
      years,
    });
  };

  const renderActionsButtons = () => {
    return (
      <Space>
        <Button loading={generatingReport} disabled={generatingReport} type="primary" icon={<ThunderboltOutlined />} onClick={handleGenerateReport}>
          Generate Report
        </Button>
        <Button loading={generatingReport} disabled={generatingReport} icon={<CloseOutlined />} onClick={handleReset}>
          Reset
        </Button>
      </Space>
    );
  };

  const handleReset = () => {
    form?.resetFields();
    setReportConfig(undefined);
  };

  return (
    <>
      <Card title="Configuration" actions={[renderActionsButtons()]} bodyStyle={{ padding: 12, margin: 0 }}>
        <Form form={form} labelCol={{ span: 6 }} wrapperCol={{ span: 14 }} autoComplete="off" style={{ margin: 0 }}>
          <Form.Item label="Year Range" name="date" style={{ margin: 0, marginBottom: 10 }} initialValue={[previousYear, currentYear]} required>
            <RangePicker allowClear={false} picker="year" disabledDate={(d) => d.isAfter(`${dayjs().year()}-12-31`) || d.isBefore("2022-01-01")} />
          </Form.Item>
          {!selectedCompanyId && (
            <CompanyReportSelector
              form={form}
              propertyToSet={"companyIds"}
              label={"Companies"}
              setCompanies={setCompanies}
              placeholder="Leave blank to select all companies"
              style={{ marginTop: 0, marginBottom: 0 }}
            />
          )}
        </Form>
      </Card>
      {generatingReport && (
        <Card title="Generating Report">
          <div style={{ textAlign: "center" }}>Loading...</div>
        </Card>
      )}
      {!generatingReport && reportConfig && <GeneratedCompanyTotalUsageReport reportConfig={reportConfig} />}
    </>
  );
};

export default CompanyTotalUsageReport;
