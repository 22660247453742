import { GroupingSelector } from "@/components";
import { constants } from "@/configs";
import { CloseOutlined, ThunderboltOutlined } from "@ant-design/icons";
import { Button, Card, Form, Space } from "antd";
import { useWatch } from "antd/es/form/Form";
import dayjs from "dayjs";
import { FC, useEffect, useState } from "react";

import GeneratedGroupingConfigurationReport from "./GeneratedGroupingConfigurationReport";
import { useSelector } from "react-redux";
import posthog from "posthog-js";

const GroupingConfigurationReport: FC = () => {
  const [form] = Form.useForm();
  const { selectedCompanyId } = useSelector((state: any) => state.company);

  const [generatingReport, setGeneratingReport] = useState<boolean>(false);

  const [reportConfig, setReportConfig] = useState<any>(undefined);

  const groupingIds: any[] = useWatch("groupingIds", form);
  const [groupings, setGroupings] = useState<any[]>([]);

  useEffect(() => {
    handleReset();
    // eslint-disable-next-line
  }, [selectedCompanyId]);

  useEffect(() => {
    if (generatingReport) setTimeout(() => setGeneratingReport(false), 500);
    // eslint-disable-next-line
  }, [generatingReport]);

  const handleGenerateReport = () => {
    setGeneratingReport(true);

    posthog.capture("generate_report", {
      reportType: "Grouping Configuration Report",
    });

    const tempGroupingsIds: any[] = groupingIds;

    setReportConfig({
      groupingIds: tempGroupingsIds,
      groupings: groupings
        ?.filter((grouping) => groupingIds?.includes(grouping?.id))
        ?.map((grouping) => grouping?.name)
        ?.join(", "),
      generatedDate: dayjs().format(constants.dateTimeFormatWithSeconds),
    });
  };

  const handleReset = () => {
    form?.resetFields();
    setReportConfig(undefined);
  };

  const renderActionsButtons = () => {
    return (
      <Space>
        <Button loading={generatingReport} disabled={generatingReport} type="primary" icon={<ThunderboltOutlined />} onClick={handleGenerateReport}>
          Generate Report
        </Button>
        <Button loading={generatingReport} disabled={generatingReport} icon={<CloseOutlined />} onClick={handleReset}>
          Reset
        </Button>
      </Space>
    );
  };

  return (
    <>
      <Card title="Configuration" actions={[renderActionsButtons()]} bodyStyle={{ padding: 12, margin: 0 }}>
        <Form form={form} labelCol={{ span: 6 }} wrapperCol={{ span: 14 }} autoComplete="off" style={{ margin: 0 }}>
          <GroupingSelector
            form={form}
            propertyToSet={"groupingIds"}
            label={"Groupings"}
            setGroupings={setGroupings}
            style={{ margin: 0, marginBottom: 10 }}
            placeholder="Leave blank to select all groupings"
          />
        </Form>
      </Card>
      {generatingReport && (
        <Card title="Generating Report">
          <div style={{ textAlign: "center" }}>Loading...</div>
        </Card>
      )}
      {!generatingReport && reportConfig && <GeneratedGroupingConfigurationReport reportConfig={reportConfig} />}
    </>
  );
};

export default GroupingConfigurationReport;
