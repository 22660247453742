import { Divider, Form, Input, InputNumber, message, Modal, Switch } from "antd";
import { FC, useState } from "react";
import { CloseOutlined, PlusOutlined } from "@ant-design/icons";
import { useWatch } from "antd/es/form/Form";
import { displayDecimals } from "@/services/utils";
import { addPendingBillableItem } from "@/apis/aggregator.api";
import useListBillingUsersInfiniteQuery from "@/queries/useListBillingUsersInfiniteQuery";

interface Props {
  userId: string;
  open: boolean;
  onClose: () => void;
}

const DocumentsAddModal: FC<Props> = (props) => {
  const { userId, open, onClose } = props;

  const { invalidateBillingUsers } = useListBillingUsersInfiniteQuery({
    disabled: true,
  });

  const [form] = Form.useForm();

  const [loading, setLoading] = useState(false);

  const handleSubmit = () => {
    form.validateFields().then((values) => {
      const requestValues = {
        ...values,
        userId,
      };
      handleAddPendingBillableItemSubmit(requestValues);
    });
  };

  const handleAddPendingBillableItemSubmit = async (data: any) => {
    setLoading(true);

    const response = await addPendingBillableItem(data);

    if (response.ok) {
      onClose();
      message.success("Pending billable item added successfully");
      setTimeout(invalidateBillingUsers, 2000);
    } else {
      message.error("Failed to add pending billable item");
    }
    setLoading(false);
  };

  const values = useWatch((x) => ({ rate: x?.rate ?? 0, quantity: x?.quantity ?? 0 }), form);

  return (
    <Modal
      open={open}
      title="Add Pending Billable Item"
      okText="Add Pending Billable Item"
      cancelText="Cancel"
      onOk={handleSubmit}
      onCancel={onClose}
      maskClosable={false}
      cancelButtonProps={{
        icon: <CloseOutlined />,
        disabled: loading,
        loading: loading,
      }}
      okButtonProps={{
        icon: <PlusOutlined />,
        disabled: loading,
        loading: loading,
      }}
    >
      <Form form={form} layout="vertical" name="form_in_modal" initialValues={{ isBillable: true, rate: 0, quantity: 1 }}>
        <Form.Item name="description" label="Description" rules={[{ required: true, message: "Please enter the description." }]}>
          <Input placeholder="Enter the description" />
        </Form.Item>
        <Form.Item name="additionalDescription" label="Additional Description">
          <Input.TextArea rows={4} placeholder="Enter additional description (Optional)" />
        </Form.Item>
        <Form.Item name="quantity" label="Quantity" rules={[{ required: true, message: "Please enter the quantity." }]}>
          <InputNumber style={{ width: "100%" }} decimalSeparator="." step={1.0} defaultValue={1} />
        </Form.Item>
        <Form.Item name="rate" label="Rate" rules={[{ required: true, message: "Please enter the rate." }]}>
          <InputNumber style={{ width: "100%" }} decimalSeparator="." prefix="$" step={1.0} defaultValue={0} />
        </Form.Item>
        <Form.Item name="isBillable" label="Is Billable">
          <Switch checkedChildren="Yes" unCheckedChildren="No" defaultChecked defaultValue={true} />
        </Form.Item>
      </Form>
      <Divider style={{ marginBottom: 5 }} />
      <div style={{ textAlign: "right" }}>
        <b style={{ fontSize: "1.2em" }}>Total: $ {displayDecimals(values ? values.rate * values.quantity : 0, 2)}</b>
      </div>
      <Divider style={{ marginTop: 5 }} />
    </Modal>
  );
};

export default DocumentsAddModal;
