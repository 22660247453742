import { get, post } from "@/services/request";

export const listBillingReport = (request: any) => {
  return post(`aggregator/report/billing`, request);
};

export const listBillingReportBreakdown = (request: any) => {
  return post(`aggregator/report/billing/breakdown`, request);
};

export const generateBillingReport = (request: any) => {
  return post(`aggregator/report/generate/billing`, request);
};

export const generateBillingBreakdownReport = (request: any) => {
  return post(`aggregator/report/generate/billing/breakdown`, request);
};

export const getCompanyTotalUsage = (companyId: any) => {
  return get(`aggregator/report/companytotalusage/${companyId}`);
};

export const getWaterRightTotalUsage = (request: { waterRightIds?: string[]; page: number; pageSize: number }) => {
  return post(`aggregator/report/waterrighttotalusage`, request);
};

export const getWaterRightLastReadingDashboard = (request: any) => {
  return post(`aggregator/dashboard/waterright/lastreading`, request);
};

export const getRestrictionDashboard = (request: any) => {
  return post(`aggregator/dashboard/restriction`, request);
};

export const getWellDashboard = (request: any) => {
  return post(`aggregator/dashboard/well`, request);
};

export const getWellMapDashboardList = (request: any) => {
  return post(`aggregator/dashboard/well/map/list`, request);
};

export const getWellDetailsForMap = (wellId: any) => {
  return get(`aggregator/dashboard/well/retrieve/${wellId}`);
};

export const getWellsForDashboardMap = (request: any) => {
  return post(`aggregator/dashboard/map/wells/list`, request);
};

export const getWaterRightQuantityPerCompany = () => {
  return get(`aggregator/dashboard/analytics/waterquantity/company`);
};

export const getWaterAllocationPerPermitType = (request: any) => {
  return post(`aggregator/dashboard/analytics/waterallocation/permittype`, request);
};

export const getWaterAllocationPerPriorityType = (request: any) => {
  return post(`aggregator/dashboard/analytics/waterallocation/prioritytype`, request);
};

export const getCropAllocationPerAcres = (request: any) => {
  return post(`aggregator/dashboard/analytics/cropallocation`, request);
};

export const getCompanyTotalAllocation = (request: any) => {
  return post(`aggregator/dashboard/analytics/allocation/company`, request);
};

export const getCompanyTotalAllocationPerAcres = (request: any) => {
  return post(`aggregator/dashboard/analytics/allocation/company/acres`, request);
};

export const getCompanyAverageInchesPerAcre = (request: any) => {
  return post(`aggregator/dashboard/analytics/allocation/company/averageinchesperacre`, request);
};

export const getCompanyMonthlyUsage = (request: any) => {
  return post(`aggregator/dashboard/analytics/allocation/company/totalmonthlyusage`, request);
};

export const listBillableEvents = (request: any) => {
  return post(`aggregator/billing/events/list`, request);
};

export const listBillableUsers = (request: any) => {
  return post(`aggregator/billing/users`, request);
};

export const updateNotBillable = (request: { id: string; billable: boolean; entityName: string; entityType: string; companyName: string | undefined }) => {
  return post(`aggregator/billing/notbillable`, request);
};

export const clearPendingBillableItems = (request: { userId: string; billableItemIds: string[] }) => {
  return post(`aggregator/billing/pending/clear`, request);
};

export const generateFinanceDocument = (request: { user: any }) => {
  return post(`aggregator/billing/document/generate`, request);
};

export const sendFinanceDocument = (request: { id: string }) => {
  return post(`aggregator/billing/document/${request.id}/send`, request);
};

export const markFinanceDocumentAsPaid = (request: { id: string }) => {
  return post(`aggregator/billing/document/${request.id}/paid`, request);
};

export const listEvents = (request: { startDate: string; endDate: string; pagination: { page: number; pageSize: number }; eventTypes: string[] }) => {
  return post(`aggregator/events/list`, request);
};

export const listFinanceDocuments = (request: { searchString?: string; type?: number; status?: number; userId?: string; pagination: { page: number; pageSize: number } }) => {
  return post(`aggregator/billing/document/list`, request);
};

export const getFinanceDocument = (id: string) => {
  return get(`aggregator/billing/document/${id}`);
};

export const updateFinanceDocument = (request: { id: string; financeDocument: any }) => {
  return post(`aggregator/billing/document/${request.id}/update`, request);
};

export const downloadFinanceDocument = (id: string) => {
  return get(`aggregator/billing/document/${id}/download`);
};

export const generateAnnualSubscription = (request: { userIds: string[]; year: number }) => {
  return post(`aggregator/billing/annual`, request);
};

export const listWaterRightAnalyticsOverview = (request: { profileId: string; companyId?: string; permitType?: string; page?: number; pageSize?: number }) => {
  return post(`aggregator/reports/analytics/overview`, request);
};

export const addPendingBillableItem = (request: { userId: string; description: string; additionalDescription: string; rate: number; quantity: number; isBillable: boolean }) => {
  return post(`aggregator/billing/pending/add`, request);
};

export const getAllBillableUserIds = () => {
  return get(`aggregator/billing/users/ids/list`);
};

export const addBillingUserNote = (request: { userId: string; note: string }) => {
  return post(`aggregator/billing/users/note/add`, request);
};

export const updateBillingUserNote = (id: string, request: { userId: string; note: string }) => {
  return post(`aggregator/billing/users/note/update/${id}`, request);
};

export const deleteBillingUserNote = (id: string, request: { userId: string; note: string }) => {
  return post(`aggregator/billing/users/note/delete/${id}`, request);
};

export const getBillingUser = (userId: string) => {
  return get(`aggregator/billing/users/${userId}`);
};
