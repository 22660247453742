import ReactDOM from "react-dom/client";
import reportWebVitals from "./reportWebVitals";
import { AuthProvider, AuthProviderProps } from "react-oidc-context";
import { constants, routes } from "@/configs";
import { RouterProvider } from "react-router-dom";
import { router } from "@/services";
import store, { persistor } from "@/stores";
import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";
import * as serviceWorkerRegistration from "./serviceWorkerRegistration";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import { lazy, Suspense, useEffect, useState } from "react";
import posthog from "posthog-js";
import { WebStorageStateStore } from "oidc-client-ts";

import "./index.scss";
import "@/slate/styles/globals.css";

posthog.init("phc_7DiApbnOtjuxanLzzOvyh7QZ5IZcfWbDy4etOvn6gvI", {
  api_host: "https://us.i.posthog.com",
  person_profiles: "identified_only", // or 'always' to create profiles for anonymous users as well
});

const oidcConfig: AuthProviderProps = {
  ...constants.oidcConfig,
  userStore: new WebStorageStateStore({ store: window.localStorage }),
};

const queryClient = new QueryClient();

const ReactQueryDevtoolsProduction = lazy(() =>
  import("@tanstack/react-query-devtools/build/modern/production.js").then((d) => ({
    default: d.ReactQueryDevtools,
  }))
);

function onSigninCallback() {
  window.history.replaceState({}, document.title, window.location.pathname);
  window.location.href = routes.dashboard.path;
}

const root = ReactDOM.createRoot(document.getElementById("root") as HTMLElement);

const App = () => {
  const [showDevtools, setShowDevtools] = useState(false);

  useEffect(() => {
    // @ts-expect-error
    window.toggleDevtools = () => setShowDevtools((old) => !old);
  }, []);

  return (
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <QueryClientProvider client={queryClient}>
          <AuthProvider {...oidcConfig} onSigninCallback={onSigninCallback}>
            <RouterProvider router={router} />
          </AuthProvider>
          <ReactQueryDevtools initialIsOpen={false} />
          {showDevtools && (
            <Suspense fallback={null}>
              <ReactQueryDevtoolsProduction />
            </Suspense>
          )}
        </QueryClientProvider>
      </PersistGate>
    </Provider>
  );
};

root.render(<App />);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.register();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
