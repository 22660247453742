import { EditOutlined } from "@ant-design/icons";
import { Button, Tooltip } from "antd";
import { FC } from "react";
import { FinanceDocumentStatus } from "../AdminInvoices";

type EditInvoiceButtonProps = {
  id: string;
  status: FinanceDocumentStatus;
  billingUserId: string;
  onEdit: (editingInvoiceId: string, billingUserId: string) => void;
};

const EditInvoiceButton: FC<EditInvoiceButtonProps> = ({ id, status, onEdit, billingUserId }) => {
  return (
    <Tooltip title={"Edit invoice"} placement="left">
      <Button style={{ paddingLeft: 0, paddingRight: 0 }} type="link" icon={<EditOutlined />} onClick={() => onEdit(id, billingUserId)} />
    </Tooltip>
  );
};

export default EditInvoiceButton;
