import { addBillingUserNote, updateBillingUserNote } from "@/apis/aggregator.api";
import { constants } from "@/configs";
import useListBillingUsersInfiniteQuery from "@/queries/useListBillingUsersInfiniteQuery";
import { CloseOutlined, PlusOutlined } from "@ant-design/icons";
import { DatePicker, Divider, Form, Input, message, Modal } from "antd";
import dayjs from "dayjs";
import { FC, useEffect, useState } from "react";

interface Props {
  userId: string;
  open: boolean;
  onClose: () => void;
  billingNotes: any[];
  billingNoteId?: string;
}

const BillingUserNoteAddEditModal: FC<Props> = (props) => {
  const { userId, open, onClose, billingNotes, billingNoteId } = props;

  const { invalidateBillingUsers } = useListBillingUsersInfiniteQuery({
    disabled: true,
  });

  const [form] = Form.useForm();

  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (billingNoteId) {
      const billingNote = billingNotes?.find((note: any) => note?.id === billingNoteId);

      form.setFieldsValue({
        date: dayjs(billingNote?.date),
        note: billingNote?.note,
      });
    } else {
      form.setFieldsValue({
        date: dayjs(),
      });
    }
  }, [billingNoteId]);

  const handleSubmit = () => {
    form.validateFields().then((values) => {
      const requestValues = {
        ...values,
        userId,
      };
      handleAddUpdateBillingUserNote(requestValues);
    });
  };

  const handleAddUpdateBillingUserNote = async (data: any) => {
    setLoading(true);

    if (billingNoteId) {
      const response = await updateBillingUserNote(billingNoteId, {
        userId: userId,
        note: data.note,
      });

      if (response.ok) {
        onClose();
        message.success("User billing note updated successfully");
        setTimeout(invalidateBillingUsers, 2000);
      } else {
        message.error("Failed to update user billing note");
      }
    } else {
      const response = await addBillingUserNote({
        note: data.note,
        userId: userId,
      });

      if (response.ok) {
        onClose();
        message.success("User billing note added successfully");
        setTimeout(invalidateBillingUsers, 2000);
      } else {
        message.error("Failed to add user billing note");
      }
    }

    setLoading(false);
  };

  return (
    <Modal
      open={open}
      title={billingNoteId ? "Update Billing Note" : "Add Billing Note"}
      okText={billingNoteId ? "Update Billing Note" : "Add Billing Note"}
      cancelText="Cancel"
      onOk={handleSubmit}
      onCancel={onClose}
      maskClosable={false}
      cancelButtonProps={{
        icon: <CloseOutlined />,
        disabled: loading,
        loading: loading,
      }}
      okButtonProps={{
        icon: <PlusOutlined />,
        disabled: loading,
        loading: loading,
      }}
    >
      <Form form={form} layout="vertical" name="form_in_modal" initialValues={{ isBillable: true, rate: 0, quantity: 1 }}>
        <Form.Item
          label="Date"
          name="date"
          rules={[
            {
              required: true,
              message: "Please select the date for the note.",
            },
          ]}
          style={{ width: "100%" }}
        >
          <DatePicker style={{ width: "100%" }} disabled={!!billingNoteId} showTime placeholder="Select date for note" format={constants.dateTimeFormat} allowClear />
        </Form.Item>
        <Form.Item name="note" label="Note" rules={[{ required: true, message: "Please enter the note." }]}>
          <Input.TextArea rows={6} placeholder="Enter the note" />
        </Form.Item>
      </Form>
      <Divider style={{ marginTop: 5 }} />
    </Modal>
  );
};

export default BillingUserNoteAddEditModal;
