import { useLocalStorage } from "@uidotdev/usehooks";
import { useMemo } from "react";
import useFeatureFlag, { FeatureFlagType } from "./useFeatureFlag";

const useDashboardOrders = () => {
  const { hasFeature: hasAnalyticsDashboard } = useFeatureFlag(FeatureFlagType.ANALYTICS_DASHBOARD);

  const defaultItems = useMemo(() => {
    const baseItems: any[] = [
      { label: "Operations", value: "2", default: true },
      { label: "Gauges", value: "3", default: false },
      { label: "Map", value: "4", default: false },
      { label: "Actions", value: "5", default: false },
    ];

    if (hasAnalyticsDashboard) {
      baseItems.unshift({ label: "Analytics", value: "1", default: false });
    }
    return baseItems;
  }, [hasAnalyticsDashboard]);

  const defaultOrders = useMemo(() => defaultItems.map((item) => ({ value: item.value, default: item.default })), [defaultItems]);

  const storedOrders = localStorage.getItem("dashboardOrder");
  const initialOrders = storedOrders ? JSON.parse(storedOrders) : defaultOrders;

  const [dashboardOrders, setDashboardOrders] = useLocalStorage<any[]>("dashboardOrder", initialOrders);

  return {
    dashboardOrders,
    setDashboardOrders,
  };
};

export default useDashboardOrders;
