import { DeleteOutlined } from "@ant-design/icons";
import { Button, Popconfirm, Tooltip } from "antd";
import { FC, useState } from "react";

type DeleteBillingUserNoteButtonProps = {
  billingNote: any;
  onDelete: (billingNote: string, onCompleted: () => void) => void;
};

const DeleteBillingUserNoteButton: FC<DeleteBillingUserNoteButtonProps> = ({ billingNote, onDelete }) => {
  const [loading, setLoading] = useState<boolean>(false);

  return (
    <Popconfirm
      placement="topRight"
      title="Delete billing note"
      description={"Are you sure you wish to delete this billing note for the billable user?"}
      onConfirm={() => {
        setLoading(true);
        onDelete(billingNote, () => setLoading(false));
      }}
      okText="Delete Note"
      cancelText="Cancel"
    >
      <Tooltip title="Delete billing note" placement="left">
        <Button loading={loading} style={{ paddingLeft: 0, paddingRight: 0 }} type="link" icon={<DeleteOutlined />} />
      </Tooltip>
    </Popconfirm>
  );
};

export default DeleteBillingUserNoteButton;
