import { deleteBillingUserNote } from "@/apis/aggregator.api";
import { constants } from "@/configs";
import useListBillingUsersInfiniteQuery from "@/queries/useListBillingUsersInfiniteQuery";
import { PlusOutlined } from "@ant-design/icons";
import { Button, ConfigProvider, Empty, message, Space, Table } from "antd";
import dayjs from "dayjs";
import _ from "lodash";
import { FC, useEffect, useState } from "react";
import BillingUserNoteAddEditModal from "./components/BillingUserNoteAddEditModal";
import DeleteBillingUserNoteButton from "./components/DeleteBillingUserNoteButton";
import EditBillingUserNoteButton from "./components/EditBillingUserNoteButton";

interface Props {
  billingNotes: any[];
  userId: string;
}

const customizeRenderEmpty = () => <div style={{ textAlign: "center" }}>No Data</div>;

const AdminBillingNotes: FC<Props> = (props) => {
  const { billingNotes, userId } = props;

  const { invalidateBillingUsers } = useListBillingUsersInfiniteQuery({
    disabled: true,
  });

  const [editingNoteId, setEditingNoteId] = useState<string | undefined>(undefined);

  const [columns, setColumns] = useState<any[]>([]);

  const [billingModalState, setBillingModalState] = useState<any>({ open: false });

  useEffect(() => {
    calculateColumns();
  }, [billingNotes]);

  useEffect(() => {
    if (editingNoteId !== undefined) {
      setBillingModalState({ open: true });
    }
  }, [editingNoteId]);

  const handleDelete = async (billingNote: any, onCompleted: () => void) => {
    const response = await deleteBillingUserNote(billingNote?.id, {
      userId,
      note: billingNote?.note,
    });

    if (response.ok) {
      message.success("User billing note deleted successfully");
      setTimeout(invalidateBillingUsers, 2000);
    } else {
      message.error("Failed to delete user billing note");
    }

    onCompleted();
  };

  const calculateColumns = () => {
    const tempColumns: any[] = [
      {
        title: "Date",
        key: "date",
        dataIndex: "date",
        width: 150,
        render: (val: any) => dayjs(val).format(constants.dateTimeFormat),
      },
      {
        title: "User",
        key: "displayName",
        dataIndex: "displayName",
        width: 150,
      },
      {
        title: "Note",
        key: "note",
        dataIndex: "note",
        render: (val: any) => (
          <div style={{ whiteSpace: "pre-wrap", wordBreak: "break-word" }}>
            {val.split("\n").map((line: string, index: number) => (
              <span key={index}>
                {line}
                <br />
              </span>
            ))}
          </div>
        ),
      },
      {
        title: "Actions",
        width: 100,
        render: (val: any, record: any) => (
          <>
            <EditBillingUserNoteButton billingNote={record} onEdit={setEditingNoteId} />
            {" | "}
            <DeleteBillingUserNoteButton billingNote={record} onDelete={handleDelete} />
          </>
        ),
      },
    ];

    setColumns(tempColumns);
  };

  return (
    <>
      <div style={{ display: "flex", flexDirection: "row", justifyContent: "flex-end", paddingBottom: 10 }}>
        <Space>
          <Button type="primary" icon={<PlusOutlined />} onClick={() => setBillingModalState({ open: true })}>
            Add Billing Note
          </Button>
        </Space>
      </div>
      <Table
        className="customSelectScrollBar"
        columns={columns}
        dataSource={billingNotes ? _.orderBy(billingNotes, ["date"], ["desc"]) : []}
        size="small"
        pagination={false}
        locale={{
          emptyText: <Empty description="No Billing Notes" image={Empty.PRESENTED_IMAGE_SIMPLE} />,
        }}
      />
      {billingModalState.open && (
        <BillingUserNoteAddEditModal
          userId={userId}
          open={billingModalState.open}
          onClose={() => {
            if (editingNoteId) setEditingNoteId(undefined);
            setBillingModalState({ open: false });
          }}
          billingNotes={billingNotes}
          billingNoteId={editingNoteId}
        />
      )}
    </>
  );
};

export default AdminBillingNotes;
