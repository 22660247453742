import { getBillingUser } from "@/apis/aggregator.api";
import defaultQueryConfig from "@/configs/defaultQueryConfig";
import CacheKeys from "@/types/CacheKeys";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import { useCallback } from "react";
import { useSelector } from "react-redux";

type BillingUsersProps = {
  userId: string;
};

export default function useGetBillableUser(props: BillingUsersProps) {
  const { profile } = useSelector((state: any) => state.user);

  const queryClient = useQueryClient();
  const {
    data: billingUser,
    isFetching,
    isRefetching,
    isFetched,
    isError,
    isSuccess,
  } = useQuery({
    queryKey: [
      CacheKeys.billingUsers,
      CacheKeys.get,
      {
        profileId: profile?.id,
        ...props,
      },
    ],
    queryFn: async () => {
      const response = await getBillingUser(props.userId);

      if (response.ok) {
        const data = await response.json();
        if (data.isSuccess) return data.value;
      }

      throw new Error("Failed to fetch billable users");
    },
    gcTime: Infinity,
    staleTime: Infinity,
    ...defaultQueryConfig,
    enabled: !!profile?.id,
  });

  const invalidateUser = useCallback(() => {
    queryClient.invalidateQueries({
      queryKey: [CacheKeys.billingUsers, CacheKeys.get],
    });
  }, [queryClient]);

  const isLoading = isFetching || isRefetching;

  return { billingUser, isFetched, isLoading, invalidateUser };
}
