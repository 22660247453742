import { CloseOutlined, ThunderboltOutlined } from "@ant-design/icons";
import { Button, Card, DatePicker, Form, Space } from "antd";
import { useWatch } from "antd/es/form/Form";
import { constants } from "@/configs";
import dayjs from "dayjs";
import { FC, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import GeneratedWaterRightTotalUsageReport from "./GeneratedWaterRightTotalUsageReport";
import { WaterRightReportSelector, WaterRightSelector } from "@/components";
import posthog from "posthog-js";

const { RangePicker } = DatePicker;

const WaterRightTotalUsageReport: FC = () => {
  const { selectedCompanyId } = useSelector((state: any) => state.company);

  const [form] = Form.useForm();

  const [generatingReport, setGeneratingReport] = useState<boolean>(false);
  const [reportConfig, setReportConfig] = useState<any>(undefined);
  const [years, setYears] = useState<any[]>([]);
  const [waterRights, setWaterRights] = useState<any[]>([]);

  const dates: any[] = useWatch("date", form);
  const waterRightIds: any[] = useWatch("waterRightIds", form);

  const currentYear = dayjs();
  const previousYear = dayjs().add(-1, "year");

  useEffect(() => {
    handleReset();
    // eslint-disable-next-line
  }, [selectedCompanyId]);

  useEffect(() => {
    setYears(dates?.map((date) => dayjs(date).year()));
    // eslint-disable-next-line
  }, [dates]);

  useEffect(() => {
    if (generatingReport) setTimeout(() => setGeneratingReport(false), 500);
    // eslint-disable-next-line
  }, [generatingReport]);

  const handleGenerateReport = () => {
    setGeneratingReport(true);

    posthog.capture("generate_report", {
      reportType: "Water Right Total Usage Report",
    });

    setReportConfig({
      companyId: selectedCompanyId,
      generatedDate: dayjs().format(constants.dateTimeFormatWithSeconds),
      years,
      waterRightIds: waterRightIds ?? [],
      waterRights:
        waterRights
          ?.filter((waterRight: any) => waterRightIds?.includes(waterRight.id))
          ?.sort((a: any, b: any) =>
            a?.fileNumber?.localeCompare(b?.fileNumber, "en", {
              numeric: true,
              sensitivity: "base",
            })
          )
          ?.map((waterRight: any) => waterRight.fileNumber)
          .join(", ") ?? [],
      allWaterRightIds: waterRights?.map((waterRight) => waterRight?.id) ?? [],
    });
  };

  const renderActionsButtons = () => {
    return (
      <Space>
        <Button loading={generatingReport} disabled={generatingReport} type="primary" icon={<ThunderboltOutlined />} onClick={handleGenerateReport}>
          Generate Report
        </Button>
        <Button loading={generatingReport} disabled={generatingReport} icon={<CloseOutlined />} onClick={handleReset}>
          Reset
        </Button>
      </Space>
    );
  };

  const handleReset = () => {
    form?.resetFields();
    setReportConfig(undefined);
  };

  return (
    <>
      <Card title="Configuration" actions={[renderActionsButtons()]} bodyStyle={{ padding: 12, margin: 0 }}>
        <Form form={form} labelCol={{ span: 6 }} wrapperCol={{ span: 14 }} autoComplete="off" style={{ margin: 0 }}>
          <Form.Item label="Year Range" name="date" style={{ margin: 0, marginBottom: 10 }} initialValue={[previousYear, currentYear]} required>
            <RangePicker allowClear={false} picker="year" disabledDate={(d) => d.isAfter(`${dayjs().year()}-12-31`) || d.isBefore("2022-01-01")} />
          </Form.Item>
          <WaterRightReportSelector
            form={form}
            propertyToSet={"waterRightIds"}
            label={"Water Rights"}
            setWaterRights={setWaterRights}
            placeholder="Leave blank to select all water rights"
            style={{ margin: 0, marginBottom: 0 }}
          />
        </Form>
      </Card>
      {generatingReport && (
        <Card title="Generating Report">
          <div style={{ textAlign: "center" }}>Loading...</div>
        </Card>
      )}
      {!generatingReport && reportConfig && <GeneratedWaterRightTotalUsageReport reportConfig={reportConfig} />}
    </>
  );
};

export default WaterRightTotalUsageReport;
