import { EditOutlined } from "@ant-design/icons";
import { Button, Tooltip } from "antd";
import { FC } from "react";

type EditBillingUserNoteButtonProps = {
  billingNote: any;
  onEdit: (billingNote: string) => void;
};

const EditBillingUserNoteButton: FC<EditBillingUserNoteButtonProps> = ({ billingNote, onEdit }) => {
  return (
    <Tooltip title="Edit billing note" placement="left">
      <Button
        style={{ paddingLeft: 0, paddingRight: 0 }}
        type="link"
        icon={<EditOutlined />}
        onClick={() => {
          onEdit(billingNote?.id);
        }}
      />
    </Tooltip>
  );
};

export default EditBillingUserNoteButton;
